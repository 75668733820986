import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import StartAndEndForgeDatePicker from 'common/components/SecondaryHeader/StartAndEndForgeDatePicker';
import { getMinDate } from 'helpers/dateUtils';
import { getSelectedYearsForBienniumYear, getValidStartAndEndDate } from 'helpers/dateHelper';
import { getCompareSamePeriodLastYearOptions } from 'helpers/fiscalPeriodUtils';
import { DATE_FORMAT, DATE_OPTIONS_TYPE } from 'appConstants';
import { isYearlyTypeDateConfigured } from 'common/config/customerConfiguration';
import ForgeDropdowns from '../ForgeDropdowns/ForgeDropdowns';
import { useMemo } from 'react';

const DateRangeSelector = (props) => {
  const {
    comparisonType, templateId, comparisonDateRanges,
    primaryDateRange, onCompareDateRangesChange,
    primaryDateType, isDashboardView
  } = props;
  let { startDate, endDate } = (comparisonDateRanges[0] || {});

  const handleSelectStartDate = (date, isApply = false) => {
    const newStartDate = moment(date).format("YYYY-MM-DD");
    const { endDate: newEndDate } = getValidStartAndEndDate({
      startDate: newStartDate,
      endDate
    });

    if (comparisonType == 'custom') {
      startDate = newStartDate;
      onCompareDateRangesChange([{ startDate: newStartDate, endDate: endDate }], comparisonType);
    } else {
      onCompareDateRangesChange([{ startDate: newStartDate, endDate: newEndDate }], comparisonType);
    }

    onAutoApply(isApply);
  }

  const handleMultipleYearSelectionChange = (years) => {
    // DashboardView is single select option
    const yearsForRage = isDashboardView ? [years] : years;
    let dateRanges = _.chain(yearsForRage).map('range').compact().uniq().value();
    dateRanges = _.orderBy(dateRanges, 'endDate', 'desc');
    onCompareDateRangesChange(dateRanges)
  }

  const handleSelectEndDate = (date, isApply = false) => {
    const newEndDate = moment(date).format("YYYY-MM-DD");
    const { startDate: newStartDate } = getValidStartAndEndDate({
      startDate, endDate: newEndDate
    });
    if (comparisonType == 'custom') {
      endDate = newEndDate;
      onCompareDateRangesChange([{ startDate: startDate, endDate: newEndDate }]);
    } else {
      onCompareDateRangesChange([{ startDate: newStartDate, endDate: newEndDate }]);
    }

    onAutoApply(isApply);
  }

  const handleDateRangeChange = (_start, _end, isApply = false) => {
    const newEndDate = moment(_end).format("YYYY-MM-DD");
    const newStartDate = moment(_start).format("YYYY-MM-DD");
    const validDateRange = getValidStartAndEndDate({
      startDate: newStartDate, endDate: newEndDate
    });
    startDate = validDateRange.startDate;
    endDate = validDateRange.endDate;
    onCompareDateRangesChange([{ startDate, endDate }]);
    onAutoApply(isApply);
  }

  const onAutoApply = (isApply) => {
    if (isApply) {
      setTimeout(() => {
        document.getElementById("apply-filter-id").click();
      }, 1000);
    }
  }

  const renderRangeSelector = () => {
    if (comparisonType == 'same_period_prior_year') {

      const yearOptions = useMemo(() => getCompareSamePeriodLastYearOptions(
        { primaryDateRange, primaryDateType, templateId }),
        [primaryDateType, templateId, primaryDateRange]
      );
      const selectYears = getSelectedYearsForBienniumYear(yearOptions, comparisonDateRanges, primaryDateType);
     
      let title = 'Years';
      if (isYearlyTypeDateConfigured()) {
        title = (primaryDateType == DATE_OPTIONS_TYPE.CUSTOM_RANGE) ? 'Calendar year(s)' : 'Fiscal year(s)';
      }
      let showHelperText = false;
      let helperText = "Please select a comparison period";
      if (_.isEmpty(selectYears)) {
        showHelperText = true;
      } else if (selectYears.length > 5) {
        showHelperText = true;
        helperText = "Please select 5 or fewer comparison periods";
      }

      return (
        <ForgeDropdowns
          key="year-selection"
          value={selectYears}
          isMultiple={!isDashboardView}
          label={title}
          showInvalid={showHelperText}
          options={yearOptions}
          hasHelperText={showHelperText}
          helperText={helperText}
          handleOnSelect={handleMultipleYearSelectionChange}
        ></ForgeDropdowns>
      )

    } else if (comparisonType == 'custom') {
      // const maxDate = moment.utc(_.get(primaryDateRange, 'startDate')).subtract(1, 'day').format(DATE_FORMAT);
      const maxDate = moment.utc().format(DATE_FORMAT);
      const minDate = getMinDate();
      const newStartDate = moment.utc(startDate) < moment.utc(minDate) ? minDate : startDate;

      return (
        <StartAndEndForgeDatePicker
          minDate={getMinDate()}
          maxDate={maxDate}
          startDate={newStartDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          handleSelectStartDate={handleSelectStartDate}
          handleSelectEndDate={handleSelectEndDate}
          isComparisonMode={true} />
      )
    } else {
      return null
    }
  }
  return renderRangeSelector();
}
DateRangeSelector.propTypes = {
  comparisonType: PropTypes.string,
  primaryDateType: PropTypes.string,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comparisonDateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })),
  primaryDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  onDateRangeChange: PropTypes.func,
  isDashboardView: PropTypes.bool,
}
export default DateRangeSelector;

import _ from 'lodash';

export const HEADER_THEME_COLOR = {
  PRIMARY: "blue_background_white_text",
  WHITE: "white_background_black_text"
};

export const HEADER_THEME_OPTIONS = [
  { name: "Blue background, white text", value: "blue_background_white_text" },
  { name: "White background, black text", value: "white_background_black_text" }
]

export const AXIS_GRANULARITY_TYPES = [
  { name: 'Default', value: 'default' },
  { name: 'Days', value: 'day' },
  { name: 'Weeks', value: 'week' },
  { name: 'Months', value: 'month' },
  { name: 'Quarters', value: 'quarter' },
  { name: 'Years', value: 'year' }
];
// TODO refactor to {display_name: "Bar Chart", type: "bar_chart" ....}
export const DRILLDOWN_VISUALIZATION_TYPES = {
  BAR_CHART: 'Bar Chart',
  LINE_CHART: 'Over Time',
  MAP: 'Map',
  PIE_CHART: 'Pie Chart',
  SCATTER_PLOT: 'Scatterplot',
  SNAPSHOT: 'Snapshot',
  TABLE: 'Table'
};

export const TARGET_ENTRY_TYPES = {
  ABOVE: 'Above',
  BELOW: 'Below'
}

export const OVERTIME_DIMENSION_ORDER = {
  HIGH_TO_LOW: { name: 'High to Low', type: 'high_to_low' },
  ALPHABETICAL: { name: 'Alphabetical', type: 'alphabetical' }
};

export const VISUALIZATION_TYPES = {
  SNAPSHOT: { name: 'Snapshot', type: 'snapshot' },
  TABLE: { name: 'Details', type: 'table' },
  OVERTIME: { name: 'Over Time', type: 'overtime' },
  MAP: { name: 'Map', type: 'map' },
  DISTRIBUTION: { name: 'Distribution', type: 'distribution' },
  TIME_OF_DAY: { name: 'Time of Day/Day of Week', type: 'time_of_day' },
}

export const FORECAST_PAGE_TYPES = {
  SELECT_METRIC_PAGE: 'SELECT_METRIC_PAGE',
  SAVED_FORECASTS_PAGE: 'SAVED_FORECASTS_PAGE',
  CREATE_FORECASTS_PAGE: 'CREATE_FORECASTS_PAGE'
}

export const SNAPSHOT_VISUALIZATION_TYPES = {
  BAR_CHART: { name: 'Bar Chart', type: 'barchart', icon: 'icons-chart-bar-horizontal' },
  PIE_CHART: { name: 'Pie Chart', type: 'piechart', icon: 'icons-chart-pie' },
  SCATTER_PLOT: { name: 'Range Chart', type: 'scatterplot', icon: 'icons-chart-point-plot' }
}

export const DATE_COMPARISON_TYPE = {
  SAME_PERIOD: "same_period_prior_year",
  PRIOR: "prior_period",
  CUSTOM: 'custom'
}

export const DATE_COMPARISON_TYPE_OPTIONS = [
  { name: 'Prior period', value: DATE_COMPARISON_TYPE.PRIOR, label: 'Prior Period' },
  { name: 'Same period, different year', value: DATE_COMPARISON_TYPE.SAME_PERIOD, label: 'Same Period' },
  { name: 'Custom date range', value: DATE_COMPARISON_TYPE.CUSTOM, label: 'Comparison Period' }
];

export const COMPARISON_MODE_OPTIONS = [
  { name: 'this period, last year' },
  { name: 'prior period' }
];

export const INSIGHT_COMPARISON_MODE_OPTIONS = [
  { name: 'same period, last year', value: 'this period, last year' },
  { name: 'prior period', value: 'prior period' }
];

export const OVERTIME_VISUALIZATION_TYPES = {
  TIMELINE: { name: 'Timeline', type: 'timeline', icon: 'icons-chart-over-time' },
  AREA: { name: 'Area', type: 'area', icon: 'icons-chart-area-stacked' },
  BURN_UP: { name: 'Burn Rate', type: 'burn_up', icon: 'icons-chart-burnup' }
}

export const PAGES = {
  DASHBOARD: 'DASHBOARD',
  DRILLDOWN: 'DRILLDOWN'
};

export const COMPARE_VIEW_DRILLDOWN_OPTIONS = [
  { name: 'top 20', rowCount: 20, type: 'top_20', dataLimit: 20 },
  { name: 'all', rowCount: 'all', type: 'all', dataLimit: 250 }
]

export const DRILLDOWN_VIEW_TYPE = [
  { name: 'Compare', type: 'compare_view' },
  { name: 'Combine', type: 'combine_view' }
]

export const HISTORICAL_FORECAST = "Historical";
export const LINEAR_FORECAST = "Linear";
export const PROPHET_FORECAST = "Prophet";
export const EXPONENTIAL_FORECAST = "Exponential";
export const FORECAST_VIEW = {
  SUMMARY_TABLE: 'summaryTable',
  OVER_TIME_CHART: 'overtimeChart'
};

//Todo: these colors to be configured from bellerophon.
export const COMPARE_VIEW_STACK_COLORS = [
  '#3F51B5',
  '#E55100',
  '#FFB300',
  '#448AFE',
  '#C2185B',
  '#4CAF50',
  '#7C4EFF',
  '#80CBC4',
  '#F06292',
  '#FF9100',
  '#FDD735',
  '#9CCC65',
  '#448AFF',
  '#9575CD',
  '#A5D6A7',
  '#00897B',
  '#684655',
  '#FE982C',
  '#F78620',
  '#D9A295',
  '#52AF43',
  '#6F9E4C',
  '#DC9A88',
  '#F16666',
  '#E42022',
  '#A6CEE3',
  '#5B9EC9',
  '#2D82AF',
  '#7EBA98',
  '#98D277'
];

export const COMPARE_VIEW_STACK_LITE_COLORS = [
  '#b2b9df',
  '#ffcdb2',
  '#ffeab9',
  '#ffeab9',
  '#f6b8d0',
  '#cde9ce',
  '#dbceff',
  '#dbceff',
  '#fad4e1',
  '#ffe0b9',
  '#fef4c7',
  '#fef4c7',
  '#cbdffe',
  '#e2d9f1',
  '#e6f3e6',
  '#99fff4',
  '#dac8d0',
  '#fee2c5',
  '#fcddc2',
  '#f4e5e2',
  '#ceeaca',
  '#d7e6cc',
  '#f5e3de',
  '#fbd5d5',
  '#f7c2c2',
  '#e6f1f7',
  '#d2e4f0',
  '#c0deee',
  '#dbece2',
  '#e2f2d9'
];

export const LINE_CHART_TOTAL_TRACE_COLOR = '#3F51B5';
export const LINE_CHART_TOTAL_COMPARE_TRACE_COLOR = '#9FA8DA';

export const LINE_CHART_TRACE_COLORS = [
  '#E55100',
  '#FFB300',
  '#448AFE',
  '#C2185B',
  '#4CAF50',
  '#7C4EFF',
  '#80CBC4',
  '#F06292',
  '#FF9100',
  '#FDD735',
  '#9CCC65',
  '#448AFF',
  '#9575CD',
  '#A5D6A7',
  '#00897B',
  '#684655',
  '#FE982C',
  '#F78620',
  '#D9A295',
  '#52AF43',
  '#6F9E4C',
  '#DC9A88',
  '#F16666',
  '#E42022',
  '#A6CEE3',
  '#5B9EC9',
  '#2D82AF',
  '#7EBA98',
  '#98D277',
  '#A6CDE2'
];

export const LINE_CHART_COMPARE_TRACE_COLORS = [
  { color: '#E55100', lite: '#EF9666' },
  { color: '#FFB300', lite: '#FFD97F' },
  { color: '#448AFE', lite: '#6891CF' },
  { color: '#C2185B', lite: '#DA749D' },
  { color: '#4CAF50', lite: '#93CF95' },
  { color: '#7C4EFF', lite: '#B194FF' },
  { color: '#80CBC4', lite: '#BFE5E1' },
  { color: '#F06292', lite: '#F6A0BD' },
  { color: '#FF9100', lite: '#FFC87F' },
  { color: '#FDD735', lite: '#FEEFAE' },
  { color: '#9CCC65', lite: '#C3E0A2' },
  { color: '#448AFF', lite: '#A1C4FF' },
  { color: '#9575CD', lite: '#CABAE6' },
  { color: '#A5D6A7', lite: '#C9E6CA' },
  { color: '#00897B', lite: '#7FC4BD' },
  { color: '#684655', lite: '#A49099' },
  { color: '#FE982C', lite: '#FECB95' },
  { color: '#F78620', lite: '#FBC18F' },
  { color: '#D9A295', lite: '#E8C6BF' },
  { color: '#52AF43', lite: '#97CF8E' },
  { color: '#6F9E4C', lite: '#B7CEA5' },
  { color: '#DC9A88', lite: '#EAC2B7' },
  { color: '#F16666', lite: '#F8B2B2' },
  { color: '#E42022', lite: '#EE797B' },
  { color: '#A6CEE3', lite: '#C0DCEB' },
  { color: '#5B9EC9', lite: '#9CC5DE' },
  { color: '#2D82AF', lite: '#96C0D7' },
  { color: '#7EBA98', lite: '#BEDCCB' },
  { color: '#98D277', lite: '#C1E4AD' },
  { color: '#A6CDE2', lite: '#C9E1ED' },
  { color: '#B7C2F6', lite: '#D1DCFF' },
  { color: '#EB7300', lite: '#FFA633' },
  { color: LINE_CHART_TOTAL_TRACE_COLOR, lite: LINE_CHART_TOTAL_COMPARE_TRACE_COLOR }
];

export const DEFAULT_ARROW_COLOR = '#3F51B5';
export const DEFAULT_UP_OR_DOWN_ARROW_COLOR = '#3F51B5';

// TODO: Change it to a hash.
export const OVERTIME_TIME_FRAME_OPTIONS = {
  ROLLING: 'rolling',
  YEAR_ON_YEAR: 'year_on_year'
}

export const OVERTIME_TIME_CHART_HEIGHT = 450;
export const OVERTIME_CHART_OPTION_WIDTH = 350;

export const DEFAULT_DROPDOWN_OPTION = { 'name': '-----' };
export const DEFAULT_SELECT_DROPDOWN_OPTION = { 'name': 'Select' };
export const DEFAULT_COMPARE_TO_DROPDOWN_OPTION = { 'name': 'None selected'};
export const DEFAULT_MAP_VIEW = 'point';

export const COMPARE_VIEW_DATA_LIMIT = 10;
export const SCATTER_PLOT_DATA_LIMIT = 20;
export const COMPARE_VIEW_ADDITIONAL_DATA_LIMIT = 250;
export const NUMBER_TYPES_FIELD = 'number';
export const STRING_TYPES_FIELD = 'text';
export const BOOLEAN_TYPES_FIELD = 'boolean';
export const DATE_TYPES_FIELD = 'date';
export const DEBOUNCE_WAIT_TIME = 100;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const BETWEEN = 'between';
export const NOT_BETWEEN = 'not between';

export const DATE_OPTIONS_TYPE = {
  YEARLY: "yearly",
  CUSTOM_RANGE: "custom_range"
}
export const COMPARISON_PERIOD_DISPLAY_NAMES_ENTRY = {
  current: '(This period)',
  previous: '(Previous period)'
}
export const EMPTY_VALUE = "(No value)";
export const OTHERS_VALUE = "Others";
export const NO_OF_BENCH_MARKS = 10;
export const DEFAULT_PRECISION = 2;
export const YEARLY_CUSTOM_OPTION = "Custom";
export const DASHBOARD_CARDS_LIMIT = 10;
export const PRIMARY_BAR_WIDTH = 0.5; // 0.7
export const PARALLEL_BAR_WIDTH = 0.25; // 0.35
export const BAR_GAP_VALUE = 0.6382;  // 0.5
export const LAST_PERIOD_COMPARISON_TYPE = { name: 'Previous period' };
export const RELATIVE_FILTER_OPTIONS = [
  { type: 'today', name: "Today" },
  { type: 'yesterday', name: "Yesterday" },
  { type: 'week', name: "This Week" },
  { type: 'month', name: "This Month" },
  { type: 'quarter', name: "This Quarter" },
  { type: 'year', name: "This Year" },
  { type: 'custom', name: "Custom" }
];
export const RELATIVE_FILTER_PERIOD_TYPE_ENTRIES = [
  { type: 'day', name: "Day(s)" },
  { type: 'month', name: "Month(s)" },
  { type: 'quarter', name: "Quarter(s)" },
  { type: 'year', name: "Year(s)" }
];
export const OPACITY_MATCHER_ENTRY = {
  '10': { 'left-label': 0.2, 'right-label': 1 },
  '20': { 'left-label': 0.4, 'right-label': 1 },
  '30': { 'left-label': 0.6, 'right-label': 1 },
  '40': { 'left-label': 0.8, 'right-label': 1 },
  '50': { 'left-label': 1, 'right-label': 1 },
  '60': { 'left-label': 1, 'right-label': 0.8 },
  '70': { 'left-label': 1, 'right-label': 0.6 },
  '80': { 'left-label': 1, 'right-label': 0.4 },
  '90': { 'left-label': 1, 'right-label': 0.2 },
  '100': { 'left-label': 1, 'right-label': 0.2 }
};

export const CUSTOM_COLOR_PALETTE = {
  sequential1: ['#F7FCFD', '#E5F5F9', '#CCECE6', '#9AD8C9', '#66C2A4'],
  sequential2: ['#FFF7EC', '#FEE8C8', '#FDD49E', '#FDBB83', '#FC8D59'],
  sequential3: ['#66C2A4', '#9AD8C9', '#CCECE6', '#E5F5F9', '#F7FCFD'],
  sequential4: ['#FC8D59', '#FDBB83', '#FDD49E', '#FEE8C8', '#FFF7EC'],
  sequential5: ['#66C2A4', '#9AD8C9', '#CCECE6', '#E5F5F9', '#F7FCFD',
    '#FFF7EC', '#FEE8C8', '#FDD49E', '#FDBB83', '#FC8D59'],
  sequential6: ['#FC8D59', '#FDBB83', '#FDD49E', '#FEE8C8', '#FFF7EC',
    '#F7FCFD', '#E5F5F9', '#CCECE6', '#9AD8C9', '#66C2A4']
};

export const CUSTOM_COLOR_PALETTE_FOR_MAP = {
  sequential5: {
    2: ['#66C2A4', '#FC8D59'],
    3: ['#66C2A4', '#F7FCFD', '#FC8D59'],
    4: ['#66C2A4', '#9AD8C9', '#FDBB83', '#FC8D59'],
    5: ['#66C2A4', '#CCECE6', '#F7FCFD', '#FEE8C8', '#FC8D59'],
    6: ['#66C2A4', '#9AD8C9', '#F7FCFD', '#FDD49E', '#FDBB83', '#FC8D59'],
    7: ['#66C2A4', '#9AD8C9', '#F7FCFD', '#FEE8C8', '#FDD49E', '#FDBB83', '#FC8D59'],
    8: ['#66C2A4', '#9AD8C9', '#CCECE6', '#F7FCFD', '#FEE8C8', '#FDD49E', '#FDBB83',
      '#FC8D59'],
    9: ['#66C2A4', '#CCECE6', '#E5F5F9', '#F7FCFD', '#FFF7EC', '#FEE8C8', '#FDD49E',
      '#FDBB83', '#FC8D59'],
    10: ['#66C2A4', '#9AD8C9', '#CCECE6', '#E5F5F9', '#F7FCFD', '#FFF7EC', '#FEE8C8',
      '#FDD49E', '#FDBB83', '#FC8D59']
  },
  sequential6: {
    2: ['#FC8D59', '#66C2A4'],
    3: ['#FC8D59', '#F7FCFD', '#66C2A4'],
    4: ['#FC8D59', '#FDBB83', '#9AD8C9', '#66C2A4'],
    5: ['#FC8D59', '#FEE8C8', '#F7FCFD', '#CCECE6', '#66C2A4'],
    6: ['#FC8D59', '#FDBB83', '#FDD49E', '#F7FCFD', '#9AD8C9', '#66C2A4'],
    7: ['#FC8D59', '#FDBB83', '#FDD49E', '#FEE8C8', '#F7FCFD', '#9AD8C9', '#66C2A4'],
    8: ['#FC8D59', '#FDBB83', '#FDD49E', '#FEE8C8', '#F7FCFD', '#CCECE6', '#9AD8C9',
      '#66C2A4'],
    9: ['#FC8D59', '#FDBB83', '#FDD49E', '#FEE8C8', '#FFF7EC', '#F7FCFD', '#E5F5F9',
      '#CCECE6', '#66C2A4'],
    10: ['#FC8D59', '#FDBB83', '#FDD49E', '#FEE8C8', '#FFF7EC', '#F7FCFD', '#E5F5F9',
      '#CCECE6', '#9AD8C9', '#66C2A4']
  }
};

export const ALL_VALUE = 'all';

export const GREYOUT_TOOLTIP_TEXT = "Using this function will not affect the selected view.";
export const DEFAULT_TOTAL_NAME = 'Total';
export const IS_TOTAL_LINE = 'IS_TOTAL_LINE';

export const NAME_ERROR_TEXT = "A name is required to send an email";
export const RECIPIENT_MAIL_ERROR_TEXT = "A valid email address is needed to send an email";
export const USER_NOT_FOUND_ERROR_TEXT = 'User is not on security list. ';
export const RECAPCHA_ERROR_TEXT = "Please confirm you are not a robot by checking the box below";
export const SUBJECT_ERROR_TEXT = "A subject is required to send an email";
export const MISSING_FIELD_ERROR_TEXT = "Missing information is required before your email can be sent.";
export const REQUIRED_TEXT = "Required";
export const ON_DISTRIBUTION_CHART_CLICK = "ON_DISTRIBUTION_CHART_CLICK";
export const ON_BAR_CLICK = "ON_BAR_CLICK";
export const MORE_THAN_FIFTY_DIMENSION_TEXT = `This view limited to 50 results.
Click here to see full results`;
export const MORE_THAN_FIFTY_TEXT = `This view is limited to 50 results. Click here to see full results.`;
export const STACK_CHART_ADD_TEXT = "@N@";
export const GROUP_BY_NONE_ENTRY = { 'name': 'None', 'field': '', 'column': '' };
export const DIMENSION_BY_NONE_ENTRY = { 'name': 'Total', 'field': 'None', 'column': '' };
export const NONE_DIMENSION_FIELD = 'None';
export const EMAIL_STRATEGY_TYPES = {
  NEVER: 'never',
  ON_SCHEDULE: 'on_schedule',
  THRESHOLD: 'threshold',
  ONLY_MONITOR_CONDITIONS: 'only_monitor_conditions',
  RECORDS_MATCH_SELECTED_FILTERS: 'records_match_on_selected_filters'
};
export const EMAIL_STRATEGY_OPTIONS = [
  { type: EMAIL_STRATEGY_TYPES.NEVER, name: 'never' },
  { type: EMAIL_STRATEGY_TYPES.ON_SCHEDULE, name: 'on a schedule' },
  { type: EMAIL_STRATEGY_TYPES.THRESHOLD, name: 'when a threshold or condition is met' },
  {
    type: EMAIL_STRATEGY_TYPES.RECORDS_MATCH_SELECTED_FILTERS,
    name: 'when records match the selected filters'
  }
];

export const NEW_CREATED_RECORD_OPTIONS = ['Only include newly created records'];
export const DEFAULT_BOOKMARK_DIMENSION_OPTION = 'all';

export const DEFAULT_TARGET_TYPE = 'absolute';

export const BENCHMARK_LINE_TYPE = ['dot', 'dashdot', 'longdash', '', 'dash'];
export const DISCRETE_AGGREGATE_TYPES = ['discrete_value', 'discrete_percentage'];
export const CUMULATIVE_AGGREGATE_TYPES = ['cumulative_value', 'cumulative_percentage'];
export const DISTRIBUTION_CHART_TYPES = {
  DISCRETE: 'discrete',
  CUMULATIVE: 'cumulative'
};

export const EVENTS = {
  DISTRIBUTION_CHART_TYPE_CHANGE: 'DISTRIBUTION_CHART_TYPE_CHANGE',
  UPDATE_SHAPE_FEATURES: 'UPDATE_SHAPE_FEATURES',
  UPDATE_POINT_FEATURES: 'UPDATE_POINT_FEATURES'
};

export const DEFAULT_BENCHMARK_COLOR = '#000000';
export const COMPARE_YEAR_TYPES = {
  SAME_LAST_PERIOD: 'Same period, last year',
  SAME_LAST_TWO_PERIODS: 'Same period, last 2 years',
  CUSTOM: 'Custom',
  PRIOR_PERIOD: 'Prior Period'
};
export const SHARE_EMAIL_LIMIT = 5;
export const CUMULATIVE_DISTRIBUTION_TYPE = 'cumulative';
export const DISCRETE_DISTRIBUTION_TYPE = 'discrete';
export const DEFAULT_GLOBAL_FILTER_TYPE = 'multi-select';
export const SHARE_EMAIL_SPLITTER = ';';
export const DEFAULT_SMALL_VIEW_TICK_SIZE = 24;
export const DEFAULT_LARGE_VIEW_TICK_SIZE = 108;
export const X_AXIS_TICK_CONFIG = {
  MOBILE: 15,
  MEDIUM: 20,
  TABLET: 24,
  DESKTOP: 36,
  FORECAST_DESKTOP: 24
};

export const REDUCE_BOOK_MARK_ZOOM_VALUE = 1.3;

export const DRAGGABLE = {
  TYPES: {
    CARD: 'card',
    COLLECTION: 'collection'
  }
}

// export const VARIANCE_MATH_VALUE_OPTIONS = {
//   DIFF: {name: "Difference", value: "difference"},
//   ABS_DIFF: {name: "Absolute Difference", value: "absolute_difference"},
//   PERCENT_DIFF: {name: "Percentage Difference", value: "percentage"},
//   ABS_PERCENT_DIFF: {name: "Absolute Percentage Difference", value: "absolute_percentage_difference"},
//   RATIO: {name: "Ratio", value: "ratio"}
// };

export const VARIANCE_MATH_VALUE_OPTIONS = {
  DIFF: { name: "Difference", value: "variance_diff" },
  PERCENT: { name: "Percentage", value: "variance_percentage" },
  RATIO: { name: "Ratio", value: "variance_ratio" }
};

export const INSIGHT_CALCULATION_OPTIONS = [
  { name: "#", value: "variance_diff" },
  { name: "%", value: "variance_percentage" }
];

export const URL_COLUMN_TYPES = ['url', 'image_url'];
export const GEO_LOCATION_COLUMN_TYPES = [
  'location', 'point', 'multipoint', 'line', 'multiline',
  'polygon', 'multipolygon', 'geocoded_column'];

export const COLLECTION_TYPES = {
  USER: "userCollection",
  BOOKMARK: "bookmarks",
  ALL_METRICS: 'allMetrics',
  BELLEROPHON: "bellerophonCollection"
}

export const COLLECTION_ID = {
  BOOKMARK: "bookmarks",
  ALL_METRICS: 'allMetrics'
}

export const CARD_TYPES = {
  TEMPLATE: 'template',
  BOOKMARK: 'bookmark'
}

export const ALL_METRICS_COLLECTION = {
  name: 'All Metrics',
  id: COLLECTION_ID.ALL_METRICS,
  type: COLLECTION_TYPES.ALL_METRICS,
};
export const BOOKMARK_COLLECTION = {
  name: 'My Views',
  id: COLLECTION_ID.BOOKMARK,
  type: COLLECTION_TYPES.BOOKMARK,
};

export const SUBSCRIPTION_FREQUENCY_OPTIONS = [
  { name: 'Day', value: 'day' },
  { name: 'Week', value: 'week' },
  { name: 'Month', value: 'month' },
];

export const TIMEZONE_OPTIONS = [
  'HST', 'AKST', 'PST', 'MST', 'CST', 'EST', 'UTC'
]

export const TIMEZONE_DEFAULT_ALERT = {
  '-10:00': 'HST',
  '-09:00': 'AKST',
  '-08:00': 'PST',
  '-07:00': 'MST',
  '-06:00': 'CST',
  '-05:00': 'EST',
  '00:00': 'UTC',
}

export const  TIME_DIFFERENCE_FOR_ZONE = {
  "US/Alaska": '-09:00',
  "US/Aleutian": '-10:00',
  "US/Arizona": '-07:00',
  "US/Central": '-06:00',
  "US/East-Indiana": '-05:00',
  "US/Eastern": '-05:00',
  "US/Hawaii": '-10:00',
  "US/Indiana": '-06:00',
  "US/Michigan": '-05:00',
  "US/Mountain": '-07:00',
  "US/Pacific": '-08:00',
  "Universal": '00:00'
}

export const WEEK_NUMBER_OPTIONS = [
  { name: '1st', value: 1 },
  { name: '2nd', value: 2 },
  { name: '3rd', value: 3 },
  { name: '4th', value: 4 }
]
export const MONTH_DAY_OPTIONS = _.map(_.range(1, 32, 1), function (number) {
  let numberWithSuffix = '';
  const value = number % 10;
  if (value == 1 && number != 11) {
    numberWithSuffix = number + "st";
  } else if (value == 2 && number != 12) {
    numberWithSuffix = number + "nd";
  } else if (value == 3 && number != 13) {
    numberWithSuffix = number + "rd";
  } else {
    numberWithSuffix = number + "th";
  }

  return { name: numberWithSuffix, value: number };
})

export const WEEKDAYS_OPTIONS = [
  { abbrName: 'S', day: 'sunday' },
  { abbrName: 'M', day: 'monday' },
  { abbrName: 'T', day: 'tuesday' },
  { abbrName: 'W', day: 'wednesday' },
  { abbrName: 'T', day: 'thursday' },
  { abbrName: 'F', day: 'friday' },
  { abbrName: 'S', day: 'saturday' }
]

export const DEFAULT_TIME = '8:00 AM';

export const TIME_PICKER_OPTIONS = [
  '12:00 AM', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM',
  '6:00 AM', '7:00 AM', DEFAULT_TIME, '9:00 AM', '10:00 AM', '11:00 AM',
  '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM',
  '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
]

export const BIENNIUM_FISCAL_YEAR = 'biennium_fiscal_year';
export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";
export const SORT_BY_OPTIONS = [
  { type: 'a-z', sortBy: 'category_name' },
  { type: 'z-a', sortBy: 'category_name' },
  { type: 'asc', sortBy: 'category_value' },
  { type: 'desc', sortBy: 'category_value' }
];
export const RANGE_BAR_COLOR = '#EB7300';

export const THIS_PERIOD_LAST_YEAR = "this period, last year";
export const CURRENCY_TYPE = 'currency';
export const AMOUNT_TYPE = 'amount';
export const SINGLE_VALUE = 1;
export const VARIANCE_COMPARISON_OPTIONS = [
  { type: 'prior_period', name: 'Prior period metrics' },
  { type: 'current_period', name: 'Current period metrics' },
  { type: 'compare_entities', name: 'Entities in a group against median band' }
]

export const DEFAULT_COLOR_PALETTE = [
  '#f0f2ff', '#eff8fb', '#eff8fb', '#f7f7f7', '#fdeddd', '#f6eef7', '#feffc8', '#fdebe1', '#fdffac',
  '#bed7e8', '#b5e3e2', '#b5cce5', '#cccccc', '#fac07e', '#bfc8e3', '#a2deb2', '#f8b2b8', '#fad04b',
  '#71abd9', '#6ac5a3', '#8f92c9', '#969696', '#f98d27', '#6da7d2', '#4cb6c6', '#f45ca1', '#f98d27',
  '#3d7ec0', '#31a75a', '#894baa', '#636363', '#e25200', '#2a919a', '#387bbb', '#c3008c', '#ec3001',
  '#1e489f', '#067126', '#80007f', '#252525', '#a33200', '#0f6f59', '#2d2298', '#79007a', '#ba001e'
];

export const PIE_CHART_ANNOTATION_OPTIONS = {
  LABELS: { name: 'Show labels', type: 'label' },
  VALUES: { name: 'Show values', type: 'value' },
  PERCENTAGES: { name: 'Show percentages', type: 'percent' }
};

export const getMapStyle = (style) => {
  return {
    'version': 8,
    'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    'sources': {
      'raster-tiles': {
        'type': 'raster',
        'tiles': [style],
        'tileSize': 256
      }
    },
    'layers': [{
      'id': 'raster-basemap-tile',
      'type': 'raster',
      'source': 'raster-tiles',
      'minzoom': 0,
      'maxzoom': 22
    }]
  };
}

export const BOOKMARK_TYPES = {
  ALERT: 'alert',
  SAVED_VIEW: 'saved view',
  RADAR: 'radar'
}

export const NONE_DATE_RANGE = 'None';

export const RELATIVE_DATE_TYPE = 'relative';
export const CALENDAR_DATE_TYPE = 'calendar_year';
export const DATE_RENDER_TYPES = ['date', 'floating_timestamp'];

export const SLA_WATCH_FILTER_OPTIONS = [
  { type: 'least_one_failed_checks', name: 'SELECTED_DIMENSION with at least one failed check' },
  { type: 'only_failed_checks', name: 'SELECTED_DIMENSION with all checks failing' },
  { type: 'all', name: 'All SELECTED_DIMENSION' },
];
export const HEAT_CHART_BACKGROUND_COLORS = ['#EFCCD2', '#DF99A5', '#D06679', '#C1334D', '#B00020'];

export const MAP_TYPES = {
  CHOROPLETH: 'choropleth'
};

export const SLA_CALCULATION_TYPES = {
  CUMULATIVE_PERCENTAGE: 'cumulative_percentage',
  PERCENTAGE: 'percentage',
  ERP: 'erp'
}

export const EPR_CALCULATION_TYPES = ['common_comparison',
  'relative_year_comparison', 'last_year_comparison'];

export const RADAR_MAP = 'radarMap';

export const SECURITYLIST_PAGE = 'securitylist_page';
export const BRANDING_PAGE = 'branding_page';
export const USER_PROFILE_PAGE = 'user_profile_page';
export const FORECASTING_PAGE = 'forecasting_page';
export const ADVANCED_SEARCH_PAGE = 'advanced-search';

export const USER_PAGE_CONFIGS = [
  {
    to: '/admin',
    title: 'User Profile',
    name: 'User profile',
    page: USER_PROFILE_PAGE,
    pageName: 'User Profile'
  },
  {
    to: '/admin',
    title: 'Branding',
    name: 'Branding',
    page: BRANDING_PAGE,
    pageName: 'Branding'
  },
  {
    to: '/admin',
    title: 'Users & Roles',
    name: 'Users and roles',
    page: SECURITYLIST_PAGE,
    pageName: 'Users & Roles'
  }
];

export const MANAGE_PAGE_CONFIG = {
  to: '/app/subscriptions',
  title: 'Settings',
  name: 'Alerts and subscriptions',
  page: 'manage',
  isCheckCondition: false,
};

export const MANAGE_FORECASTING_PAGE_CONFIG = {
  to: '/forecasting',
  title: 'Advanced Forecasting',
  name: 'Advanced forecasting',
  page: FORECASTING_PAGE,
  pageName: 'Advanced Forecasting'
};

export const MANAGE_ADVANCED_SEARCH_PAGE_CONFIG = {
  to: '/advanced-search',
  title: 'Advanced Search',
  name: 'Advanced Search',
  page: ADVANCED_SEARCH_PAGE,
  pageName: 'Advanced Search'
};

export const REG_EXP_NUMERIC = /^-?[0-9]\d*(\.\d+)?$/;

export const TYLERBLUE_COLOR_PALETTE = "3F51B5";
export const ORANGE900_COLOR_PALETTE = "E55100";
export const DEFAULT_VIEW_DELETE_MESSAGE = "Would you like to remove this saved view from this collection? " +
  " It will still be available in My Views.";
export const COLLECTION_CARD_DELETE_MESSAGE = "Would you like to remove this tile from " +
  " this collection? It will still be available in All Metrics."
export const VIEW_DELETE_MESSAGE = "Would you like to delete your saved view? " +
  "The saved view will be removed from all collections.";
export const ALERT_DELETE_MESSAGE = "Would you like to delete your alert? " +
  "There is no undo.";


export const MY_VIEW_DELETE_TITLE = "Delete saved view"
export const MY_VIEW_BUTTON_BUTTON = "Delete";
export const COLLECTION_VIEW_DELETE_TITLE = "Remove tile from collection"
export const COLLECTION_VIEW_DELETE_BUTTON = "Remove"

export const DOWNLOAD_TABLE_WARNING_MESSAGE = `This CSV has more than a million rows.
It may be too large to open in some spreadsheet programs.
Consider using a program designed to handle larger datasets, such as R or Python, or
adding filters to limit the size of the dataset.`;

export const THIRD_PARTY_DATA_WARNING_MESSAGE = `The Third-Party Data you are downloading
contains Confidential Information. It is available for internal use only,
and for internal reporting solely for the benefit of your organization.
It must not be publicly published.
However, you may publish data visualizations built from aggregated summary data.`

export const FORECAST_MODEL_DELETE_MESSAGE = "Are you sure you want to remove this model.";

export const DEFAULT_DISPLAY_ORDER = 'default';
export const DEFAULT_DATE_TYPES = [
  { 'name': 'Fiscal Year', 'value': 'yearly' },
  { 'name': 'Date Range', 'value': 'custom_range' },
  { 'name': 'Relative', 'value': 'relative' },
  { 'name': 'Calendar Year', 'value': 'calendar_year' }
];
export const SAVE_VIEW_INFO_TEXT = `Saving this view with the date filter applied will restrict
  this view to the time period selected`;
export const COMPARISON_PERIOD_TYPES = [
  { name: 'Prior period', value: 'prior period' },
  { name: 'Same period, prior year', value: 'this period, last year' },
];

export const COMPARISON_PERIOD = 'comparisonPeriod';
export const SHAPE_AREA_TABS = [
  { name: 'Selected Area', value: 'selectedArea', key: 'selectAreaName' },
  { name: 'Comparison Area', value: 'comparisonArea', key: 'comparisonAreaName' }
]

export const RADAR_CHART_PRE_BAR_COLOR = '#3F51B5';
export const RADAR_CHART_POST_BAR_COLOR = '#C7CCEB';

export const RADAR_SELECTED_MINI_MAP_SESSION_KEY = 'is_selected_mini_map_clicked';
export const RADAR_COMPARISON_MINI_MAP_SESSION_KEY = 'is_comparison_mini_map_clicked';
export const MONTHLY_REGULARITY_TYPES = {
  WEEK: 'week_period',
  DAY: 'time_period'
}

export const FORGE_BAR_DEFAULT = {
  mode: { fixed: 'Fixed', clustered: 'Clustered' },
  align: { start: 'Start', center: 'Center', end: 'End' },
  underline: false,
  stacked: false,
  disabled: false,
}

export const DEFAULT_DESCRIPTION = 'Add a description.';
export const MINIMUM_MAP_RESIZE_HEIGHT = 100;
export const DEFAULT_MAP_CONTAINER_HEIGHT = 650;

export const BLANK_USER_COLLECTION_MESSAGE = `Congratulations, you’ve created a new collection!
You can add metrics to this collection by dragging and dropping metric tiles
onto the collection title.`

export const USER_SEARCH_TYPES = {
  EMAIL: 'Email',
  ROLES: 'Roles',
  PERMISSIONS: 'Permissions'
};

export const TIME_OF_DAYS = [
  { "id": "empty", 'hour': '', "short": "", "long": "" },
  { "id": "00", 'hour': 0, "short": "12a", "long": "12am" },
  { "id": "01", 'hour': 1, "short": "1a", "long": "1am" },
  { "id": "02", 'hour': 2, "short": "2a", "long": "2am" },
  { "id": "03", 'hour': 3, "short": "3a", "long": "3am" },
  { "id": "04", 'hour': 4, "short": "4a", "long": "4am" },
  { "id": "05", 'hour': 5, "short": "5a", "long": "5am" },
  { "id": "06", 'hour': 6, "short": "6a", "long": "6am" },
  { "id": "07", 'hour': 7, "short": "7a", "long": "7am" },
  { "id": "08", 'hour': 8, "short": "8a", "long": "8am" },
  { "id": "09", 'hour': 9, "short": "9a", "long": "9am" },
  { "id": "10", 'hour': 10, "short": "10a", "long": "10am" },
  { "id": "11", 'hour': 11, "short": "11a", "long": "11am" },
  { "id": "12", 'hour': 12, "short": "12p", "long": "12pm" },
  { "id": "13", 'hour': 13, "short": "1p", "long": "1pm" },
  { "id": "14", 'hour': 14, "short": "2p", "long": "2pm" },
  { "id": "15", 'hour': 15, "short": "3p", "long": "3pm" },
  { "id": "16", 'hour': 16, "short": "4p", "long": "4pm" },
  { "id": "17", 'hour': 17, "short": "5p", "long": "5pm" },
  { "id": "18", 'hour': 18, "short": "6p", "long": "6pm" },
  { "id": "19", 'hour': 19, "short": "7p", "long": "7pm" },
  { "id": "20", 'hour': 20, "short": "8p", "long": "8pm" },
  { "id": "21", 'hour': 21, "short": "9p", "long": "9pm" },
  { "id": "22", 'hour': 22, "short": "10p", "long": "10pm" },
  { "id": "23", 'hour': 23, "short": "11p", "long": "11pm" }
];

export const TIME_OF_WEEKS = [
  { "id": "Su", "day": 0, "short": "Su", "medium": "Sun", "short_x": "S", "long": "Sunday" },
  { "id": "Mo", "day": 1, "short": "Mo", "medium": "Mon", "short_x": "M", "long": "Monday" },
  { "id": "Tu", "day": 2, "short": "Tu", "medium": "Tue", "short_x": "T", "long": "Tuesday" },
  { "id": "We", "day": 3, "short": "We", "medium": "Wed", "short_x": "W", "long": "Wednesday" },
  { "id": "Th", "day": 4, "short": "Th", "medium": "Thu", "short_x": "T", "long": "Thursday" },
  { "id": "Fr", "day": 5, "short": "Fr", "medium": "Fri", "short_x": "F", "long": "Friday" },
  { "id": "Sa", "day": 6, "short": "Sa", "medium": "Sat", "short_x": "S", "long": "Saturday" },
];

export const WITH_OUT_SUMMARY_TABLE_CHARTS = [
  VISUALIZATION_TYPES.TIME_OF_DAY.type, VISUALIZATION_TYPES.DISTRIBUTION.type
]

export const DEFAULT_WEEK_KEY = 'medium';

export const DEFAULT_BAR_COMPARE_COLOR = [
  '#3d5afe', '#d14900', '#ffc107', '#2e7d32', '#3f51b5', '#f6eef7', '#feffc8', '#fdebe1', '#fdffac',
];
export const BENCHMARK_TEXT = "benchmark"

export const MONTH_OF_YEAR = [
  { name: 'Jan', value: '1' },
  { name: 'Feb', value: '2' },
  { name: 'Mar', value: '3' },
  { name: 'Apr', value: '4' },
  { name: 'May', value: '5' },
  { name: 'Jun', value: '6' },
  { name: 'Jul', value: '7' },
  { name: 'Aug', value: '8' },
  { name: 'Sep', value: '9' },
  { name: 'Oct', value: '10' },
  { name: 'Nov', value: '11' },
  { name: 'Dec', value: '12' }
]

export const FORECAST_PERIOD_TYPES = [
  { name: "Month(s)", value: 'M' },
  { name: 'Quarter(s)', value: 'Q' },
  { name: 'Year(s)', value: 'Y' },
]

export const FORECASTING_TYPE = {
  PROPHET: 'Prophet',
  HISTORICAL_AVG: 'Historical_average',
  SIMPLE_EXPONENTIAL: 'Simple_exponential_smoothing',
  AUTOREGRESSIVE: 'Autoregressive_moving_average',
};

export const FORECASTING_TYPE_LABEL = {
  Prophet: 'Prophet',
  Historical_average: 'Historical average',
  Simple_exponential_smoothing: 'Simple exp smoothing',
  Autoregressive_moving_average: 'Autoregressive moving average',
};

export const HISTORICAL_FORECAST_COLORS = [
  '#FFB300',
  '#448AFE',
  '#C2185B',
  '#4CAF50',
  '#7C4EFF',
  '#80CBC4',
  '#F06292',
  '#FF9100',
  '#FDD735',
  '#9CCC65',
  '#448AFF',
  '#9575CD'
];

export const FORECAST_MODEL_TYPES = [
  { name: 'Prophet', type: FORECASTING_TYPE.PROPHET, value: null, color: '#E55100' },
  {
    name: 'Historical average', type: FORECASTING_TYPE.HISTORICAL_AVG, value: '3',
    index: 0, color: '#FFB300'
  },
  {
    name: 'Simple exponential smoothing', type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL,
    value: null, color: '#689F38'
  },
  { name: 'Autoregressive moving average', type: FORECASTING_TYPE.AUTOREGRESSIVE, value: null }
]
export const EXPONENTIAL_FORECAST_COLOR = '#689F38';
export const PROPHET_FORECAST_COLOR = '#E55100';
export const HISTORICAL_FORECAST_COLOR = '#FFB300';
export const HISTORICAL_TOTAL_DATA_COLOR = '#3F51B5';
export const DEFAULT_PAGINATION_SIZE = 15;

export const COLLECTION_TAB = {
  name: 'Collections and Subscriptions',
  type: 'collection'
};

export const SAVED_VIEWS_TAB = {
  name: 'Saved Views',
  type: BOOKMARK_TYPES['SAVED_VIEW']
};

export const ALERTS_TAB = {
  name: 'Alerts',
  type: BOOKMARK_TYPES['ALERT']
};

export const ADVANCE_SEARCH_PAGE = {
  SEARCH_PAGE: 'search_page',
  DETAIL_PAGE: 'detail_page',
  REPORT_PAGE: 'report_page'
}

export const SEARCH_METHOD = {
  SUBJECT_PROPERTY: 'subject_property',
  CUSTOM: 'custom'
}

export const PREVIOUS_URL_UPDATED = 'PREVIOUS_URL_UPDATED';

export const RADAR_TRACK_OTHER = 'Other';
export const PLOTLY_RANGE_UPDATE_DATE_TYPE = 'plotly_range_update_date_type';
export const PLOTLY_SLIDER_RANGE_OPTION = 'plotly_slider_range_option';
export const FILTER_SELECT_ALL = "Select all";

export const METRIC_CHART_ICONS = [
  { chartType: 'barchart', icon: 'icons-chart-bar-horizontal' },
  { chartType: 'scatterplot', icon: 'icons-chart-point-plot' },
  { chartType: 'piechart', icon: 'icons-chart-pie' },
  { chartType: 'timeline', icon: 'icons-chart-over-time' },
  { chartType: 'area', icon: 'icons-chart-area-stacked' },
  { chartType: 'burn_up', icon: 'icons-chart-burnup' },
  { chartType: 'point', icon: 'icons-map-point' },
  { chartType: 'heat', icon: 'icons-heatmap' },
  { chartType: 'choropleth', icon: 'icons-map-cloropleth' }
]

export const KEY_ON_BOARD_WIZARD = "isOnBoardWizard";
export const KEY_USER_READ_MESSAGE = "isUserUnReadMessage";

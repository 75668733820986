import moment from 'moment';
import _ from 'lodash';
import {
  OVERTIME_VISUALIZATION_TYPES,
  COMPARE_VIEW_STACK_COLORS,
  NO_OF_BENCH_MARKS,
  PROPHET_FORECAST,
  EXPONENTIAL_FORECAST,
  COMPARE_VIEW_STACK_LITE_COLORS,
  HISTORICAL_FORECAST,
  FORECASTING_TYPE
} from 'appConstants';

import {
  getCurrentDateRangePeriods,
  getComparisonDateRangePeriods,
  getBenchMarkConfigEntries,
  getSecondaryMetricEntries,
  isTraceVisible,
  getPeriodType,
  getRangePeriods,
  getTailingDropStartDate,
  isQuarterPeriod,
  isYearOnYear
} from './vizOptionsHelper';
import { disableMetricTotal, isDiscreteModeData } from 'common/config/viewConfiguration';
import {
  formatResultsInBienniumYearWise,
  getBienniumProjectionDimension,
  isBienniumFiscalYear
} from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';

import {
  getConfiguredDataEndDate
} from 'common/config/customerConfiguration';
import { isEmptyApiData } from 'modules/visualization/helper';
import {
  getProjectionDataItems,
  getProjectionExtraItems,
  getProjectionPeriods,
  getTailingDropStartDateQuarter
} from './Helpers/projectionHelper';
import { getDimensionsConfigs, addTotalLegend } from './Helpers/dimensionHelper';
import { sumApiDataForActualData } from './Helpers/apiDataHelper';
import { getYearTextByRange } from 'helpers/dateHelper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import {
  getGroupByQuarterData,
  updateAdjustedValueForData,
  getValidChartValue
} from './Helpers/overtimeHelper';
import { getDummyProjections, getLastCurrentPeriodValue } from './Helpers/projectionDataGenerator';
import {
  getValidFormattedData,
  getForecastFormattedData,
  getCurrentQuarterStart,
  getCurrentQuarterEnd,
  isForecastEndDateIsBeforeToday
} from 'pages/Forecasting/ForecastHelper';
import { VIEW_MODE } from '../constants';
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export default {
  shouldUpdate: (vizOptions, previousVizOptions) => {
    const isProjectOptionChanged = !_.isEqual(
      _.get(previousVizOptions, 'projectionType'), _.get(vizOptions, 'projectionType')
    ) ||
      !_.isEqual(previousVizOptions['projectionPercent'], vizOptions['projectionPercent']) ||
      !_.isEqual(previousVizOptions['projectionEnabled'], vizOptions['projectionEnabled']) ||
      !_.isEqual(previousVizOptions['forecastingOption'], vizOptions['forecastingOption']) ||
      !_.isEqual(_.get(vizOptions, 'forecastPrepareDataTime'),
        _.get(previousVizOptions, 'forecastPrepareDataTime'));

    if (isProjectOptionChanged) {
      return true;
    }
    return (
      false
      // _.get(vizOptions, '') !== _.get(previousVizOptions, '')
    );
  },
  formatData: (vizOptions, rawApiData) => {
    if (_.isEmpty(rawApiData)) {
      return {}
    }
    const { compareYearRanges, viewEntry, dateRangeMode, dateRange,
      axisGranularity, renderType, showLegendTotalLine, viewMode} = vizOptions;
    const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
    if(isForecastingView){
      rawApiData = updateAdjustedValue(rawApiData, vizOptions, periods);
    }
    const apiData = formatApidata(rawApiData, vizOptions);
    const periods = getCurrentDateRangePeriods(vizOptions, apiData);
    const isSameMonth = moment(dateRange.startDate).isSame(dateRange.endDate, 'month');
    const subtractValue = (isSameMonth || axisGranularity == 'day') ? 0 : 1;
    const tailingDropStartDate = getTailingDropStartDateQuarter(
      getTailingDropStartDate(vizOptions).subtract(subtractValue, 'day'), vizOptions, true);

    const currentPeriods = _.filter(periods, (period) => {
      return !period.isAfter(tailingDropStartDate) &&
        !period.isSame(tailingDropStartDate);
    });
    const comparisonPeriods = getComparisonDateRangePeriods(vizOptions);
    const comparisonYear = _.size(compareYearRanges) == 1 ?
      getYearTextByRange(compareYearRanges[0], dateRangeMode) : null;
    const dataRangeEndDate = _.get(vizOptions, 'dateRange.endDate');
    const fiscalYear = !_.isEmpty(dataRangeEndDate) ? moment(dataRangeEndDate).format('YYYY') : '';
    const total = _.get(apiData, 'total', []);
    const entries = _.get(apiData, 'entries', {});
    let comboChartSecondaryResults = _.get(apiData, 'combo_chart_secondary_results', []);
    const comparisonTotal = _.get(apiData, 'comparison_total_result', []);
    const comparisonDimensionEntriesResult = _.get(apiData, 'comparison_dimension_entries_result', {});
    const shouldShowTotalLine = showTotalLine(total, entries, apiData, vizOptions) &&
      !disableMetricTotal(viewEntry) &&
      !_.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', ''));
    const isBurnup = renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
    const isDiscreteMode = isDiscreteModeData(viewEntry);
    const totalData = isBurnup && isDiscreteMode ?
                      total : updateAdjustedValueToValue(apiData, vizOptions, true);
    const isSmallView = viewMode === VIEW_MODE.SMALL;
    let tailDropItems = total;
    if (isForecastingView && isForecastEndDateIsBeforeToday({dateRange: dateRange}, axisGranularity)){
      const tailDateRange = {
        startDate: moment(dateRange.endDate, "YYYY-MM-DD").startOf('month').format("YYYY-MM-DD"),
        endDate: moment(tailingDropStartDate).add(subtractValue, 'day').format("YYYY-MM-DD")
      };
      tailDropItems =
        sumApiDataForActualData(_.get(apiData, 'api_data.total', total), vizOptions, tailDateRange);
    }

    let formattedData = {
      "current": {
        "total": shouldShowTotalLine || isForecastingView ?
          formatEntityDataItems(total, currentPeriods, vizOptions) : [],
        "entries": _.mapValues(entries, (dataItems, dimension) => {
          return formatEntityDataItems(dataItems, currentPeriods, vizOptions, dimension);
        }),
        "combo_metrics": _.map(comboChartSecondaryResults, (results) => {
          return _.mapValues(results.total, (metricValues, metricKey) => {
            const newMetricValues = isForecastingView ?
            updateAdjustedValueForData(metricValues, vizOptions, metricKey ) : metricValues;
            return formatEntityDataItems(newMetricValues, currentPeriods, vizOptions)
          });
        }),
        year: fiscalYear,
      },
      "comparison": {
        "total": _.isEmpty(comparisonTotal) || disableMetricTotal(viewEntry) ? [] : formatEntityDataItems(
          comparisonTotal,
          comparisonPeriods,
          vizOptions
        ),
        "entries": _.mapValues(comparisonDimensionEntriesResult, (dataItems) => {
          return formatEntityDataItems(dataItems, comparisonPeriods, vizOptions);
        }),
        year: comparisonYear,
      },
      "benchMarks": formatBenchmarkEntries(vizOptions, _.get(apiData, 'bench_mark_entries')),
      "tailingDrop": {
        "total": shouldShowTotalLine || isForecastingView  ?
          formatTailingDropItems(tailDropItems, vizOptions) : [],
        "entries": _.mapValues(entries, (dataItems) => {
          return formatTailingDropItems(dataItems, vizOptions);
        }),
        year: fiscalYear
      },
      "tailingDropComparison": {
        "total": disableMetricTotal(viewEntry) ? [] : formatTailingDropItems(comparisonTotal, vizOptions),
        "entries": _.mapValues(comparisonDimensionEntriesResult, (dataItems) => {
          return formatTailingDropItems(dataItems, vizOptions);
        }),
        year: comparisonYear
      },
      "tailingDropComboMetrics": _.map(comboChartSecondaryResults, (results) => {
        return _.mapValues(results.total, (metricValues) => {
          return formatTailingDropItems(metricValues, vizOptions)
        });
      }),
      "actualDropItems": {
        "total":  isForecastingView ?
          formatActualDropItems(_.get(apiData, 'api_data.total', total), vizOptions) : []
      },
      "projection": {
        "total": shouldShowTotalLine && !isForecastingView ? formatProjectionEntries(
          _.concat(total, comparisonTotal),
          currentPeriods,
          vizOptions, getProjectionExtraItems(apiData, vizOptions, 'total')) : [],
        "entries": formatProjectionRawEntries(entries, rawApiData, apiData, currentPeriods, vizOptions),
        "combo_projection_total": getComboChartProjectionTotal(comboChartSecondaryResults, currentPeriods,
          vizOptions, apiData),
        "total_last_current": (isForecastingView) ? getTotalLastPoint(
          totalData,
          currentPeriods,
          vizOptions,
          getProjectionExtraItems(apiData, vizOptions, 'total'),undefined, true) : [],
        "forecastingProjections": isForecastingView ?
            getForecastingProjections(
              _.concat(updateAdjustedValueToValue(apiData,vizOptions, true), comparisonTotal),
              currentPeriods, vizOptions,
              updateAdjustedValueToValue(apiData, vizOptions))
          : [],
      }
    };

    const allEntries = {
      ...entries,
      ...comparisonDimensionEntriesResult
    };
    formattedData["dimensionConfigs"] =
      getDimensionsConfigs(allEntries, vizOptions, entries, shouldShowTotalLine);
    if(!isEmptyApiData(apiData, vizOptions)){
      formattedData["comboMetricConfigs"] = _.chain(getSecondaryMetricEntries(vizOptions)).
        map((secondaryMetricEntry, index) => {
          const dimension = _.get(secondaryMetricEntry, 'name')
          return {
            metricField: _.get(secondaryMetricEntry, 'field'),
            dimension,
            traceId: dimension,
            color: COMPARE_VIEW_STACK_COLORS[index + 1],
            liteColor: COMPARE_VIEW_STACK_LITE_COLORS[index + 1],
            metricEntry: _.omit(secondaryMetricEntry, ['parent_queries']),
            isTotalLine: true,
            visible: isTraceVisible(vizOptions, { traceId: dimension })
          }
        }).
        value();
    }

    if (shouldShowTotalLine && (showLegendTotalLine || isSmallView)) {
      formattedData = addTotalLegend(formattedData, vizOptions);
    }

    return formattedData;
  }
};

const updateAdjustedValue = (rawApiData, vizOptions) => {
  const rawApiCloneData = _.cloneDeep(rawApiData);
  const apiTotalData = _.get(rawApiCloneData, 'total', []);

  updateAdjustedValueForData(apiTotalData, vizOptions, 'total');

  return rawApiCloneData;
}

const updateAdjustedValueToValue = (apiData, vizOptions, isOnlyTotal = false) => {
  const apiCloneData = _.cloneDeep(apiData);
  const apiTotalData = apiCloneData.total;

  const { projectionAdjustedValues } = vizOptions;

  _.forEach(apiTotalData, (datum) => {
    let availableDataItem = _.find(projectionAdjustedValues, (dataItem) => {
      return dataItem.period == datum.period;
    });

    const  adujustValue = _.get(availableDataItem, 'adjustValue', _.get(availableDataItem, 'value'));
    datum['value'] = _.toNumber(_.get(datum, 'value')) !== 0 ? _.get(datum, 'value') :  adujustValue;
  })

  return isOnlyTotal ? (apiTotalData || []) : apiCloneData;
}

const getComboChartProjectionTotal = (comboChartSecondaryResults, currentPeriods,
  vizOptions, apiData) => {
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  return _.map(comboChartSecondaryResults, (results, index) => {
    return _.mapValues(results.total, (metricValues, dimension) => {
      const newMetricValues = isForecastingView ?
          updateAdjustedValueForData(metricValues, vizOptions, dimension ) : metricValues;
      return getComboChartProjection(newMetricValues, currentPeriods, vizOptions, apiData, dimension, index);
    });
  });
}

const getComboChartProjection = (metricValues, currentPeriods, vizOptions, apiData, dimension, index) => {
  const isForecastingView = _.get(vizOptions, 'isForecastingView', false);
  if (isForecastingView){
    const forecastingOption = _.get(vizOptions, 'forecastingOption', {});
    const projections = _.get(forecastingOption, 'projectionTypes');
    let totalProjections = [], projectionEntries, forecastVizOptions = _.cloneDeep(vizOptions);
    if (_.includes(projections, EXPONENTIAL_FORECAST) || _.includes(projections, PROPHET_FORECAST)){
      forecastVizOptions['projectionType'] = PROPHET_FORECAST;
      projectionEntries = _.get(apiData,
        `forecasting_data.combo_chart_secondary_totals.${dimension}`, []
      );
      totalProjections.push({
        data: formatProjectionEntries(
          metricValues, currentPeriods, forecastVizOptions, projectionEntries, dimension
        ),
        type: PROPHET_FORECAST
      });
    }

    if(_.includes(projections, HISTORICAL_FORECAST)) {
      forecastVizOptions['projectionType'] = HISTORICAL_FORECAST;
      projectionEntries = _.get(apiData,
        `historic_data.combo_chart_secondary_results[${index}].total.${dimension}`, []
      );
      totalProjections.push({
        data: formatProjectionEntries(
          metricValues, currentPeriods, forecastVizOptions, projectionEntries, dimension
        ),
        type: HISTORICAL_FORECAST

      });
    }
    return totalProjections;
  } else {
    let projectionEntries = [];
    if (_.includes([EXPONENTIAL_FORECAST, PROPHET_FORECAST], vizOptions.projectionType)) {
      projectionEntries = _.get(apiData,
        `forecasting_data.combo_chart_secondary_totals.${dimension}.forecasted_data`, []
      );
    } else {
      projectionEntries = _.get(apiData,
        `historic_data.combo_chart_secondary_results[${index}].total.${dimension}`, []
      );
    }

    return formatProjectionEntries(
      metricValues, currentPeriods, vizOptions, projectionEntries, dimension
    );
  }
}

const formatEntityDataItems = (dataItems, periods, vizOptions, dimension) => {
  const { renderTimeFrame, dateRange, compareYearRanges, isComboChart } = vizOptions;
  const isBurnup = vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type;
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const periodType = getPeriodType(vizOptions);
  const sanitizedDataItems = _.map(dataItems, (dataItem) => {
    return {
      ...dataItem,
      periodMmt: moment(dataItem.period).startOf('day'),
    };
  });

  if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges) && !isComboChart) {
    let yearwisePeriod = [];
    const dateRangeByDimension = getDateRangeByYearText(vizOptions);
    let { startDate, endDate } = dateRangeByDimension[dimension] || {};
    if (getConfiguredDataEndDate().isBefore(endDate)) {
      endDate = getConfiguredDataEndDate().format(DATE_FORMAT);
    }
    yearwisePeriod = getRangePeriods(startDate, endDate, periodType);
    const tailingDropStartDate = getTailingDropStartDateQuarter(
      getTailingDropStartDate(vizOptions).subtract(1, 'day'), vizOptions
    );
    periods = _.filter(yearwisePeriod, (period) => {
      return !period.isAfter(tailingDropStartDate);
    });
  }

  // Sort and also fill missing items.
  const formattedDataItems = formatDataByFillingGaps(sanitizedDataItems, periods);

  if (isBurnup && isDiscreteMode && !_.isEmpty(formattedDataItems)) {
    const formatData = _addBurnupAccumulatedDate(dataItems, formattedDataItems, periods[0], periodType);
    return isQuarterPeriod(vizOptions) ? getGroupByQuarterData(formatData, false, false, true) : formatData;
  } else {
    const returnFormattedData = isQuarterPeriod(vizOptions) ?
      getGroupByQuarterData(formattedDataItems, false, false, true) : formattedDataItems;
    return returnFormattedData;
  }
};

const formatBenchmarkEntries = (vizOptions, benchMarkApiData) => {
  const benchmarkConfigEntries = getBenchMarkConfigEntries(vizOptions);

  return _.map(benchmarkConfigEntries, (benchmarkConfigEntry) => {
    const valueKeys = _.chain(0).
      range(NO_OF_BENCH_MARKS).
      map((index) => index > 0 ? `value${index}` : 'value').
      value();

    const benchMarkField = _.get(benchmarkConfigEntry, 'field');
    const valuesFromConfig = _.values(_.pick(benchmarkConfigEntry, valueKeys));
    const valuesFromApiData = _.map(benchMarkApiData, benchMarkField);
    const isCheckFromConfigValue = _.isEmpty(valuesFromConfig) || _.isEmpty(_.get(valuesFromConfig, '0'));

    return {
      configEntry: benchmarkConfigEntry,
      values: isCheckFromConfigValue ? valuesFromApiData : valuesFromConfig
    }
  });
};

const formatProjectionRawEntries = (entries, rawApiData, apiData, currentPeriods, vizOptions) => {
  const { renderTimeFrame, dateRange, compareYearRanges, isComboChart } = vizOptions;

  if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges) && !isComboChart) {
    let formattedResult = {};
    const dataItems = _.get(rawApiData, 'total', []);
    const extraItems = getProjectionExtraItems(rawApiData, vizOptions, 'total');
    const dimension = isBienniumFiscalYear(vizOptions) && _.size(compareYearRanges) > 1 ?
      getBienniumProjectionDimension(entries, vizOptions) :
      _.chain(entries).keys().first().value();

    formattedResult[dimension] = formatProjectionEntries(dataItems, currentPeriods,
      vizOptions, extraItems, dimension);
    return formattedResult;
  } else {
    return _.mapValues(entries, (dataItems, dimension) => {
      const compareDataItems = _.get(apiData, 'comparison_dimension_entries_result.' + dimension, []);
      const projectionEntries = _.get(
        getProjectionExtraItems(apiData, vizOptions, 'dimensions'), dimension, []
      );

      const formatProjectionEntriesData = formatProjectionEntries(
        _.concat(dataItems, compareDataItems),
        currentPeriods,
        vizOptions,
        projectionEntries, dimension);
      return formatProjectionEntriesData;
    });
  }
}

const showHistoricProjections = (dataItems, vizOptions, currentPeriods) => {
  const totals = formatEntityDataItems(dataItems, currentPeriods, vizOptions)
  const forecastAttributeOptions = {
    currentChartView: vizOptions.renderType,
    selectedForecastMetric: {},
    isBurnup: vizOptions.renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type,
    isDiscreteMode: true,
    projectionAdjustedValues: vizOptions.projectionAdjustedValues
  };
  const totalResults = {
    current: {
      total: totals
    }
  }

  const forecastFormattedData = getForecastFormattedData(totalResults, forecastAttributeOptions);
  const validDataOptions = {
    formattedData: forecastFormattedData,
    currentForecastDateRange: { dateRange: vizOptions.dateRange },
    axisGranularity: vizOptions.axisGranularity
  }

  const formattedData = getValidFormattedData(validDataOptions);
  const isFoundGapValue = _.some(formattedData, (datum) => {
    const value = getValidChartValue(datum);
    const adjustValue = Number(_.get(datum, 'adjustValue', 0));

    return (value == 0 && adjustValue == 0 )
  });
  const { dateRange } = vizOptions;
  const startDate = moment(dateRange['startDate']);
  const endDate = moment(dateRange['endDate']);
  const diffDays = endDate.diff(startDate,'days');
  const isOneYear = diffDays > 365;
  return (isFoundGapValue || !isOneYear);
}

const getTotalLastPoint =
  (dataItems, currentPeriods, vizOptions, previousDataItems, dimension, onlyLastCurrentItem) => {
  const {dateRange, axisGranularity, isForecastingView } = vizOptions;
  const isQuarterType = axisGranularity == 'quarter';
  if(isForecastingView && isQuarterType &&
    isForecastEndDateIsBeforeToday({ dateRange }, axisGranularity)) {
      const formattedTotalItems = formatEntityDataItems(dataItems, currentPeriods, vizOptions)
      const lastDataItem = _.last(formattedTotalItems);
      return [{
        ...lastDataItem,
        ignoreProjection: true
      }];
    } else {
      return formatProjectionEntries(
        dataItems, currentPeriods, vizOptions, previousDataItems, dimension, onlyLastCurrentItem)
    }
}

const getForecastingProjections = (dataItems, currentPeriods, vizOptions, apiData) => {
  const forecastingOption = _.get(vizOptions, 'forecastingOption', {});
  const forecastModelOptions = _.get(vizOptions.forecastingOption,'forecastModelOptions',[]);
    let totalProjections = [];
    const projectionEnabled = _.get(vizOptions, 'projectionEnabled', false);
    const projections = _.get(forecastingOption, 'projectionTypes');
    let forecastVizOptions = _.cloneDeep(vizOptions);
    if(_.includes(projections, PROPHET_FORECAST)) {
      forecastVizOptions['projectionType'] = PROPHET_FORECAST;
      const prophetForecast = _.find(forecastModelOptions, {type: FORECASTING_TYPE.PROPHET});
      const previousDataItems = getProjectionExtraItems(apiData, forecastVizOptions , 'total');
      totalProjections.push(
        {
          data: formatProjectionEntries(dataItems, currentPeriods, forecastVizOptions, previousDataItems),
          type: FORECASTING_TYPE.PROPHET,
          name: prophetForecast['name']
        }
      );
    }

    if(_.includes(projections, EXPONENTIAL_FORECAST)) {
      forecastVizOptions['projectionType'] = FORECASTING_TYPE.SIMPLE_EXPONENTIAL;
      const exponentialForecast = _.find(forecastModelOptions, {type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL});
      const previousDataItems = getProjectionExtraItems(apiData, forecastVizOptions , 'total');
      totalProjections.push(
        {
          data: formatProjectionEntries(dataItems, currentPeriods, forecastVizOptions, previousDataItems),
          type: FORECASTING_TYPE.SIMPLE_EXPONENTIAL,
          name: exponentialForecast['name']
        }
      );
    }

    if( _.includes(projections, HISTORICAL_FORECAST) &&
      !showHistoricProjections(dataItems, vizOptions, currentPeriods)) {
      forecastVizOptions['projectionType'] = HISTORICAL_FORECAST;
      const historicModels = _.filter(forecastModelOptions, {type: FORECASTING_TYPE.HISTORICAL_AVG});
      _.forEach(historicModels, (model) => {
        const previousDataItems = getProjectionExtraItems(apiData, forecastVizOptions,
                                  'multiple', `percent_${model.value}`);
        totalProjections.push({
          data: formatProjectionEntries(dataItems, currentPeriods, forecastVizOptions, previousDataItems),
          type: FORECASTING_TYPE.HISTORICAL_AVG,
          name : model['name'],
          color: model['color']
        });
      });
    } else if(!projectionEnabled) {
      const projectionPeriods = getProjectionPeriods(vizOptions);
      const dummyData = getDummyProjections(projectionPeriods)
      totalProjections.push(
        {
          data: isQuarterPeriod(vizOptions) ? getGroupByQuarterData(dummyData, vizOptions, true) : dummyData,
          type: "None"
        }
      )
    }

    return totalProjections;
}

const formatProjectionEntries = (
  dataItems, currentPeriods, vizOptions, previousDataItems, dimension, onlyLastCurrentItem = false) => {
  const {dateRange, axisGranularity, isForecastingView, renderType } = vizOptions;

  const endDate = _.get(dateRange, 'dateRange.endDate')
  if (renderType == "area" || !vizOptions.projectionEnabled) {
    return []
  }
  const isQuarterType = axisGranularity == 'quarter';

  let totalDataItems = dataItems;
  const isBurnup = (renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type);
  if(isYearOnYear(vizOptions) && isQuarterPeriod(vizOptions) && isBurnup){
    totalDataItems = _.uniqBy(dataItems, 'period');
  }
  const periodType = getPeriodType(vizOptions);
  const dataEndDate = isForecastingView &&
    isForecastEndDateIsBeforeToday({ dateRange }, axisGranularity) ?
    moment(endDate).startOf(periodType) : getTailingDropStartDate(vizOptions);

  const tailingDropStartDate = getTailingDropStartDateQuarter(
    dataEndDate.subtract(1, 'day'), vizOptions);

  // Filter the dataItems which is less than `configuredDataEndDate`.
  const sanitizedDataItems = _.chain(totalDataItems).map((dataItem) => {
    const dataItemPeriod = new Date(dataItem.period);
    const tailingDropDate = new Date(tailingDropStartDate);
    const currentQuarterStart = getCurrentQuarterStart(tailingDropDate);
    const currentQuarterEnd = getCurrentQuarterEnd(tailingDropDate);

    if (moment(dataItem.period).isAfter(tailingDropStartDate) ||
      (isQuarterType && dataItemPeriod >= currentQuarterStart && dataItemPeriod <= currentQuarterEnd)
  ) {
      return;
    }

    return {
      ...dataItem,
      dimension: dimension,
      periodMmt: moment(dataItem.period).startOf('day')
    };
  }).
    compact().
    value();

  // Filter the currentPeriods which is less than `configuredDataEndDate`.
  // because the `configuredDataEndDate` is the last point of actual trace.
  const activeCurrentPeriods = _.filter(currentPeriods, (period) => {
    return period.isBefore(getTailingDropStartDateQuarter(dataEndDate,
      vizOptions, true));
  });

  if (isQuarterPeriod(vizOptions)) {
    const lastPeriod = _.last(activeCurrentPeriods);
    const projectionStartDate = getTailingDropStartDateQuarter(lastPeriod, vizOptions, true);
    activeCurrentPeriods.push(projectionStartDate);
  }

  const sanitizedPreviousDataItems = _.map(previousDataItems, (dataItem) => {
    return {
      ...dataItem,
      dimension: dimension,
      periodMmt: moment(_.get(dataItem, 'period')).startOf('day')
    };
  });
  const newSanitizedDataItems = isQuarterPeriod(vizOptions) ?
    getGroupByQuarterData(sanitizedDataItems) :
    sanitizedDataItems;

  let projectionItems = null;
  if (onlyLastCurrentItem && isForecastingView) {
    projectionItems = [getLastCurrentPeriodValue(
      activeCurrentPeriods, newSanitizedDataItems, vizOptions, dimension)];

  } else {
    projectionItems = getProjectionDataItems(
      newSanitizedDataItems, activeCurrentPeriods, vizOptions, sanitizedPreviousDataItems, dimension
    );
  }
  return isQuarterPeriod(vizOptions) ? getGroupByQuarterData(projectionItems, true) : projectionItems;
}

const formatTailingDropItems = (dataItems, vizOptions) => {
  const { renderType, dateRange, axisGranularity } = vizOptions;
  const isQuarterType = axisGranularity == 'quarter';
  const isBurnup = (renderType === OVERTIME_VISUALIZATION_TYPES.BURN_UP.type);
  const isDiscreteMode = isDiscreteModeData(vizOptions.viewEntry);
  const periodType = getPeriodType(vizOptions)
  const tailingDropStartDate = getTailingDropStartDateQuarter(
    getTailingDropStartDate(vizOptions), vizOptions, true);

  let sanitizedDataItems = _.chain(dataItems).map((dataItem, index) => {
    const dataItemPeriod = new Date(dataItem.period);
    const tailingDropDate = new Date(tailingDropStartDate);
    const currentQuarterStart = getCurrentQuarterStart(tailingDropDate);
    const currentQuarterEnd = getCurrentQuarterEnd(tailingDropDate);

  if (moment(dataItem.period).isAfter(tailingDropStartDate, periodType) ||
      moment(dataItem.period).isSame(tailingDropStartDate, periodType) ||
    ( isQuarterType && dataItemPeriod >= currentQuarterStart && dataItemPeriod <= currentQuarterEnd)
  ) {
    return {
        ...dataItem,
        periodMmt: moment(dataItem.period).startOf('day'),
        dataIndex: index
    };
  }
  }).
    compact().
    value();

  const momentOfStartDate = moment(dateRange.startDate);
  if (momentOfStartDate > tailingDropStartDate) {
    const periods = getRangePeriods(dateRange.startDate, dateRange.endDate, periodType);
    // Sort and also fill missing items.
    sanitizedDataItems = formatDataByFillingGaps(sanitizedDataItems, periods);
  }

  // Adding first accumulated value to first value
  // so in burn up trailing point will form last burn up value
  if (isBurnup && isDiscreteMode && !_.isEmpty(sanitizedDataItems)) {
    const formatData = _addBurnupAccumulatedDate(
      dataItems, sanitizedDataItems, tailingDropStartDate, periodType
    );
    return isQuarterPeriod(vizOptions) ? getGroupByQuarterData(formatData, vizOptions) : formatData;
  } else {
    const returnFormattedData = isQuarterPeriod(vizOptions) ?
      getGroupByQuarterData(sanitizedDataItems, false) : sanitizedDataItems;
    return returnFormattedData;
  }
}

const formatActualDropItems = (dataItems, vizOptions) => {
  const {dateRange } = vizOptions;
  const periodType = getPeriodType(vizOptions)
  const tailingDropStartDate = getTailingDropStartDateQuarter(
    getTailingDropStartDate(vizOptions), vizOptions, true);
  let actualStartDate = '';
  if (periodType == 'day' || periodType == 'week'){
    actualStartDate = moment(dateRange.endDate, "YYYY-MM-DD").
      subtract(1, periodType).format("YYYY-MM-DD");
  } else {
    actualStartDate = moment(dateRange.endDate, "YYYY-MM-DD").
      subtract(1, periodType).startOf('month').format("YYYY-MM-DD");
  }

  const actualDateRange = {
    startDate: actualStartDate,
    endDate: tailingDropStartDate
  };
  const actualDropItems = sumApiDataForActualData(dataItems, vizOptions, actualDateRange);
  let sanitizedDataItems = _.chain(actualDropItems).map((dataItem, index) => {
    if (moment(dataItem.period).isBefore(tailingDropStartDate, periodType) &&
        moment(dataItem.period).isAfter(actualStartDate, periodType)
    ) {
      return {
          ...dataItem,
          periodMmt: moment(dataItem.period).startOf('day'),
          dataIndex: index
      };
    }
  }).
    compact().
    value();

  return isQuarterPeriod(vizOptions) ?
  getGroupByQuarterData(sanitizedDataItems, false) : sanitizedDataItems;

}

const _addBurnupAccumulatedDate = (dataItems, sanitizedDataItems, accumulatedEndDate, periodType) => {
  if (_.isEmpty(dataItems) || _.isEmpty(sanitizedDataItems)) {
    return sanitizedDataItems;
  }

  const filteredDataItems = _.filter(dataItems, (item) => {
    return moment(item.period).isBefore(accumulatedEndDate, periodType);
  });
  const accumulatedValue = _.sumBy(filteredDataItems, (item) => {
    return Number(item.value || 0);
  });
  const accumulatedSecondaryValue = _.sumBy(filteredDataItems, (item) => {
    return Number(item.secondary_total || 0);
  });

  if (!_.isNull(sanitizedDataItems[0].value)) {
    sanitizedDataItems[0].value = Number(sanitizedDataItems[0].value || 0) + accumulatedValue;
  }

  if (!_.isNull(sanitizedDataItems[0].secondary_total)) {
    sanitizedDataItems[0].secondary_total = (
      Number(sanitizedDataItems[0].secondary_total || 0) + accumulatedSecondaryValue
    );
  }

  return sanitizedDataItems
}

const formatDataByFillingGaps = function (sanitizedDataItems, periods) {
  const groupedByPeriodDataItems = _.groupBy(sanitizedDataItems, (datum) => {
    return datum.periodMmt.format(DATE_FORMAT);
  });

  return _.map(periods, (period) => {
    const periodMoment = moment(period).format(DATE_FORMAT);
    if (groupedByPeriodDataItems[periodMoment]) {
      return _.get(groupedByPeriodDataItems[periodMoment], '0');
    }

    // Filling Gaps
    return {
      ..._.get(sanitizedDataItems, '[0]', {}),
      period: period.format(DATE_FORMAT),
      periodMmt: period,
      value: null,
      secondary_total: null
    };
  });
}

const showTotalLine = (total, entries, apiData, vizOptions) => {
  const { isComboChart, renderType } = vizOptions;
  const isAreaChart = (renderType == "area");
  const comparisonEntries = _.get(apiData, 'comparison_dimension_entries_result', {});
  const comparisonTotal = _.get(apiData, 'comparison_total_result', []);
  if (_.isEmpty(total) || isAreaChart) {
    return false;
  } else if (_.size(entries) > 1 || isComboChart || _.size(comparisonEntries) > 1) {
    return true
  } else {
    const totalValues = _.map(total, 'value');
    const dimensionValues = _.map(_.get(_.values(entries), '[0]', []), 'value');
    let showComparisonTotal = false;
    if (!_.isEmpty(comparisonTotal)) {
      const totals = _.map(comparisonTotal, 'value');
      const values = _.map(_.get(_.values(comparisonEntries), '[0]', []), 'value');
      showComparisonTotal = !_.isEqual(totals, values);
    }
    return !_.isEqual(totalValues, dimensionValues) || showComparisonTotal;
  }

}

const formatApidata = (rawApiData, vizOptions) => {
  const {
    renderTimeFrame, dateRange, isComboChart,
    compareYearRanges, dateRangeMode, renderType,
    axisGranularity
  } = vizOptions;
  const isAreaChart = (renderType == "area");
  if (isComboChart || isAreaChart) {
    return rawApiData;
  }
  if (shouldDisableDimensions(dateRange, renderTimeFrame, compareYearRanges)) {
    const isBienniumAndMoreThanTwoCompareYears = isBienniumFiscalYear(vizOptions) &&
      _.size(compareYearRanges) > 1;
    let yearWiseEntries = isBienniumAndMoreThanTwoCompareYears ?
      formatResultsInBienniumYearWise(rawApiData['total'], dateRange, dateRangeMode) :
      formatResultsInYearWise(rawApiData['total'], dateRange, dateRangeMode, axisGranularity);

    if (!_.isEmpty(compareYearRanges)) {
      _.each(compareYearRanges, (compareRange) => {
        const comparisonTotals = _.get(rawApiData, 'comparison_total_result', []);
        let entries = isBienniumAndMoreThanTwoCompareYears ?
          formatResultsInBienniumYearWise(comparisonTotals, compareRange, dateRangeMode) :
          formatResultsInYearWise(comparisonTotals, compareRange, dateRangeMode, axisGranularity);
        yearWiseEntries = _.merge({}, yearWiseEntries, entries);
      });
    }

    const formattedApiData = _.cloneDeep(rawApiData);
    formattedApiData['total'] = [];
    formattedApiData['previous_projection_total'] = [];
    formattedApiData['comparison_total_result'] = [];
    formattedApiData['entries'] = yearWiseEntries;
    formattedApiData['previous_projection_entries'] = formatResultsInYearWise(
      rawApiData['previous_projection_total'], dateRange, dateRangeMode, axisGranularity);
    return formattedApiData;
  } else {
    return rawApiData;
  }
}

const formatResultsInYearWise = (results, dateRange, dateRangeMode, axisGranularity) => {
  let formattedResult = {};
  const { startDate, endDate } = dateRange;
  const year = getYearTextByRange(dateRange, dateRangeMode);
  const yearWiseResultEntries = _.filter(results, (entry) => {
    if(_.includes(['year', 'month'], axisGranularity)){
      return moment(entry.period).isBetween(startDate, endDate, axisGranularity, '[]');
    }else{
      return moment(entry.period).startOf('day').isBetween(startDate, endDate, 'month', '[]');
    }
  });
  formattedResult[year] = yearWiseResultEntries;
  return formattedResult;
};

const getDateRangeByYearText = (vizOptions) => {
  const { dateRange, compareYearRanges, dateRangeMode } = vizOptions;
  let dateRangeByText = {};
  dateRangeByText[getYearTextByRange(dateRange, dateRangeMode)] = dateRange;
  _.each(compareYearRanges, (compareRange) => {
    dateRangeByText[getYearTextByRange(compareRange, dateRangeMode)] = compareRange;
  });
  return dateRangeByText;
}

export const getEveryNthElementFromFirst = (arr, n) => {
  return arr.filter((_, index) => index % n === 0);
};

import {
    CURRENCY_TYPE,
    DEFAULT_MAP_VIEW,
    SNAPSHOT_VISUALIZATION_TYPES,
    OVERTIME_TIME_FRAME_OPTIONS,
    REDUCE_BOOK_MARK_ZOOM_VALUE,
    OVERTIME_VISUALIZATION_TYPES,
    NONE_DIMENSION_FIELD
} from 'appConstants';
import {
    getDefaultSortOption,
    getDefaultVisualizationChartView,
    getDefaultSecondaryMetricEntry,
    getDefaultBenchMarkEntries,
    getSecondaryMetricEntry,
    getBenchMarkMetricEntries,
    getSortByOption,
    getDefaultCompareYearRange
} from 'helpers/visualizationHelper';
import {
    getDrilldownEntryWithoutViewEntry,
    getSelectedDimensionEntry,
} from 'helpers/templateHelper';
import {
    getDefaultDateMode,
    getComparisonPeriodDateRanges
} from 'helpers/dateHelper';
import { isNumericDimensionField } from 'helpers/snapshotRendererHelper';
import {
    getApiParamsForMap,
    getDrilldownEntryForSnapshot,
    sanitizeCommonFilterParam,
} from 'helpers/apiParamsHelper';
import {
    isComboChartEnable,
    isShowProjectionDefault
} from 'common/config/visualizationConfiguration';

import {
    getCurrentTemplateEntryById,
    getDefaultShapeDatasetEntry,
    getTableColumnEntries,
} from 'common/config/templateConfiguration';
import { getMapViews } from 'modules/Map/helpers/MapOptionsHelper';
import {
    getDefaultProjectionMetric,
    canShowProjectionMetric,
    isAboveConfiguredEndPeriod,
} from 'modules/visualization/LineChart/Helpers/projectionHelper';
import {
    getDefaultAxisGranularity,
    showAllYears,
    showOvertimeChartDimensions,
    defaultNumberOfdimensions,
    isDimensionSortOrderHightToLow
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import {
    getCurrentDrilldownDimensionColumn
} from 'helpers/chartDataHelper';
import { getInitialDistributionOptions } from 'helpers/distributionHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { isTemplateLevelMapSettingEnable, getConfig } from 'common/config/customerConfiguration';
import { getTimeOfDayUrl } from 'common/api/timeOfDayApi';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getComparisonBarName } from 'helpers/snapshotRendererHelper';

const CHART_LIMIT = 10;
const BAR_CHART_API_LIMIT = 6;

export const getQueryParams = (propsOption) => {
    const {
        drilldownEntry,
        viewEntry,
        commonFilters,
        visualizationEntry,
        visualizationType,
        mapOptions
    } = propsOption;
    const dimensionField = _.get(
        drilldownEntry,
        'currentDrilldownDimensionField'
    );
    const templateId = _.get(
        drilldownEntry,
        'currentDrilldownTemplateId'
    );
    let currentSortOption = getDefaultSortOption(dimensionField, viewEntry, null, templateId);
    const chartType = getCurrentVizChartView(propsOption);
    const isPieChart = chartType === SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type;
    const isScatterChart = chartType === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type;
    const limitCount =
        isPieChart
            ? 10
            : BAR_CHART_API_LIMIT;
    const snapshotVisualizationEntry = _.get(
        visualizationEntry,
        'snapshot',
        {}
    );
    let secondaryMetricField = null, secondaryDateRange = {}, isComparePrevious = false;

    if (SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type == chartType) {
        const secondaryMetricEntry = getDefaultSecondaryMetricEntry(
            viewEntry,
            'snapshot',
            chartType
        );
        secondaryMetricField = _.get(secondaryMetricEntry, 'field', null);
    }

    if (!_.isEmpty(snapshotVisualizationEntry)) {
        const defaultChartView = getCurrentVizChartView(propsOption);
        const secondaryMetricEntry = getSecondaryMetricEntry(
            viewEntry,
            'snapshot',
            defaultChartView,
            snapshotVisualizationEntry.currentSecondaryMetricField
        );
        secondaryMetricField = _.get(secondaryMetricEntry, 'field', null);

        const sortParamsHash = {
            currentDrilldownViewEntry: viewEntry,
            currentDrilldownDimensionField: dimensionField,
        };
        const sortOption = getSortByOption(
            sortParamsHash,
            snapshotVisualizationEntry.currentBarChartSortType,
            'sort'
        )
        currentSortOption = {
            sortBy: _.get(sortOption, 'sortBy', ''),
            sortOrder: _.get(sortOption, 'sortOrder', ''),
        };
    }
    const drilldownEntryWithoutViewEntry = getDrilldownEntryWithoutViewEntry(
        drilldownEntry, visualizationType
    );
    const isNumberDimension = isNumericDimensionField({
        currentDrilldownTemplateId: templateId,
        currentDrilldownDimensionField: dimensionField
    });

    let queryParams = {
        page: 0,
        limit: limitCount,
        sortBy: _.get(currentSortOption, 'sortBy', ''),
        sortOrder: _.get(currentSortOption, 'sortOrder', ''),
        drilldownEntry: getDrilldownEntryForSnapshot(
            JSON.stringify(drilldownEntryWithoutViewEntry)
        ),
        secondaryDateRange: JSON.stringify(secondaryDateRange),
        commonFilters: JSON.stringify(sanitizeCommonFilterParam(commonFilters)),
        secondaryMetricField,
        comparePrevious: isComparePrevious,
        ignoreRowCount: true,
        ignoreGroupBy: true,
        shapeGroupId: _.get(
            mapOptions,
            'shape.datasetEntry.shape_dataset_id'
        ),
        shapeIds: _.get(mapOptions, 'shape.selectedShapeIds', []),
        polygonsGeojson: _.get(mapOptions, 'filteredGeojson', {}),
        showScatterPlotRange: isScatterChart
    };

    if ( _.isEmpty(dimensionField) && commonFilters['comparisonModeOn']) {
        queryParams = _.merge({}, queryParams, {
            compareYearRanges: JSON.stringify(commonFilters['comparisonDateRanges'])
        });
    }

    return {
        ...queryParams,
        ...((isNumberDimension && !isPieChart) ? { dimension_type: 'range' } : {})
    };
};

export const getCurrentVizChartView = (propsOption) => {
    const { viewEntry, visualizationType, currentVizChartType } = propsOption;
    if (currentVizChartType) {
        return currentVizChartType;
    } else {
        return getDefaultVisualizationChartView(viewEntry, visualizationType);
    }
};

export const getCustomColumnEntries = (propsOption) => {
    const { drilldownEntry } = propsOption;
    const currentTemplate = getCurrentTemplateEntryById(
        drilldownEntry['currentDrilldownTemplateId']
    );
    return getTableColumnEntries(currentTemplate['template_id']);
};

export const getTimeOfDayUrlBasedOnProps = (propsOption) => {
	const { drilldownEntry, mapOptions, commonFilters, cardEntry } = propsOption;
	const drilldownWithoutViewEntry = getDrilldownEntryWithoutViewEntry(
			drilldownEntry
	);
	const shapeGroupId = _.get(
		cardEntry,
		'shape_dataset_entries[0].shape_dataset_id'
	) || _.get(mapOptions, 'shape.datasetEntry.shape_dataset_id');

	const apiParams = getApiParamsForMap({
		drilldown: drilldownWithoutViewEntry,
		shapeGroupId,
		mapOptions,
		commonFilters,
	});

    return getTimeOfDayUrl(apiParams);
}

export const getMapAttributes = (propsOption) => {
    const {
        drilldownEntry,
        mapOptions,
        commonFilters,
        cardEntry,
        viewEntry,
        isBookmarkCard,
        cardImageId
    } = propsOption;
    const templateId = _.get(drilldownEntry, 'currentDrilldownTemplateId');
    const templateEntry = getCurrentTemplateEntryById(templateId);
    const currentMapStyleEntry = _.first(
        _.get(cardEntry, 'map.style_entries', [{}])
    );
    const shapeGroupId =   _.get(
        mapOptions,
        'shape.datasetEntry.shape_dataset_id'
    ) || _.get(getDefaultShapeDatasetEntry(templateId), 'shape_dataset_id', '')

    const drilldownWithoutViewEntry = getDrilldownEntryWithoutViewEntry(
        drilldownEntry
    );

    let config;
    if(isTemplateLevelMapSettingEnable()){
      config = !_.isEmpty(templateEntry) ? templateEntry : getConfig();
    } else {
      config = getConfig();
    }

    const centerLng = _.get(config, 'map.centerLng');
    const centerLat = _.get(config, 'map.centerLat');
    const metricTileMapZoom = _.get(config, 'map.metric_tile_map_zoom');
    const mapZoom = _.get(config, 'map.zoom');
    if (_.isEmpty(centerLng) || _.isEmpty(centerLat) || _.isEmpty(mapZoom)) {
        return null;
    }
    const center = [parseFloat(centerLng || 0), parseFloat(centerLat || 0)];
    const bookmarkCenter = _.get(mapOptions, 'centerAndZoom.center');
    const zoom =
        metricTileMapZoom || _.get(config, 'map.mini_map_zoom', mapZoom) - 0.6;
    const apiParams = getApiParamsForMap({
        drilldown: drilldownWithoutViewEntry,
        shapeGroupId,
        mapOptions,
        commonFilters,
    });
    const bookmarkZoom = _.get(mapOptions, 'centerAndZoom.zoom', mapZoom);
    const defaultMapView = _.get(getMapViews(), DEFAULT_MAP_VIEW);
    return {
        apiParams,
        center:
            isBookmarkCard && !_.isEmpty(bookmarkCenter) ? bookmarkCenter : center,
        viewEntry: viewEntry,
        drilldown: drilldownWithoutViewEntry,
        currentMapView: _.get(mapOptions, 'currentMapView', defaultMapView),
        currentMapStyleEntry,
        shapeGroupId,
        cardImageId,
        zoom: isBookmarkCard ? bookmarkZoom - REDUCE_BOOK_MARK_ZOOM_VALUE : zoom,
        polygonsGeojson: _.get(mapOptions, 'filteredGeojson', {}),
        selectedShapeIds: _.get(mapOptions, 'shape.selectedShapeIds', []),
        selectedShapesExtent: _.get(mapOptions, 'shape.selectedShapesExtent', {})
    };
}

export const getSnapShotChartAttributes = (propsOption) => {
    const {
        viewEntry,
        isEmbed,
        visualizationType,
        isChartAndTotalLoading,
        onDataLoading,
        drilldownEntry,
        currentVizChartType,
        showChartValues,
        visualizationEntry,
        currentDimensionTotal,
        cardImageId,
        commonFilters
    } = propsOption;

    const {
        currentDrilldownTemplateId,
        currentDrilldownDimensionField,
    } = drilldownEntry;

    let defaultChartView = getCurrentVizChartView(propsOption);
    const isComparisonEnabled = currentDrilldownDimensionField == NONE_DIMENSION_FIELD &&
        commonFilters['comparisonModeOn'];
    const snapshotVisualizationEntry = _.get(
        visualizationEntry,
        'snapshot',
        {}
    );
    if (
        !_.isEmpty(snapshotVisualizationEntry) &&
        _.isEmpty(currentVizChartType)
    ) {
        defaultChartView = getDefaultVisualizationChartView(
            viewEntry,
            visualizationType
        );
    }
    let secondaryMetricEntry = getDefaultSecondaryMetricEntry(
        viewEntry,
        'snapshot',
        defaultChartView
    );

    let benchMarkEntries = getDefaultBenchMarkEntries(
        viewEntry,
        visualizationType,
        defaultChartView,
        currentDrilldownTemplateId,
        currentDrilldownDimensionField
    );

    if (!_.isEmpty(snapshotVisualizationEntry)) {
        secondaryMetricEntry = getSecondaryMetricEntry(
            viewEntry,
            'snapshot',
            defaultChartView,
            snapshotVisualizationEntry.currentSecondaryMetricField
        );
        const benchMarkMetricNames =
            snapshotVisualizationEntry.currentBenchMarkMetricNames;
        benchMarkEntries = getBenchMarkMetricEntries(
            viewEntry,
            'snapshot',
            defaultChartView,
            benchMarkMetricNames
        );
    }

    if (isComparisonEnabled && currentDrilldownDimensionField == NONE_DIMENSION_FIELD){
        const primaryMetricName = getPrimaryMetricName(viewEntry);
        secondaryMetricEntry = {
        ...viewEntry,
        name: getComparisonBarName(commonFilters, primaryMetricName),
        render_type: "bullet",
        field: commonFilters['comparisonType'],
        isComparisonEntry: true
        }
    }

    const currentDrilldownDimension = getSelectedDimensionEntry(
        currentDrilldownTemplateId,
        currentDrilldownDimensionField
    );
    const isCurrencyDimensionField = _.get(currentDrilldownDimension, 'renderType') === CURRENCY_TYPE;
    const isNumberDrilldownDimension =
        _.get(currentDrilldownDimension, 'renderType') === 'number' &&
        _.get(currentDrilldownDimension, 'renderAxis') === 'range';
    const barAttributes = {
        apiParams: getQueryParams(propsOption),
        isEmbed,
        isCurrencyDimensionField,
        viewEntry,
        secondaryMetricEntry,
        onDataLoading,
        isChartAndTotalLoading,
        benchMarkEntries,
        templateId: currentDrilldownTemplateId,
        dimensionName: _.get(currentDrilldownDimension, 'name'),
        viewMode: VIEW_MODE.SMALL,
        currentSnapshotView: defaultChartView,
        showChartValues,
        ignoreCompareDateRange: !_.isEmpty(currentDrilldownDimension),
        isComparisonEnabled,
        commonFilters: commonFilters,
        compareYearRanges: commonFilters['comparisonDateRanges'],
        cardImageId
    };
    const scatterChartAttributes = {
        apiParams: getQueryParams(propsOption),
        benchMarkEntries,
        isEmbed,
        isCurrencyDimensionField,
        isNumericDimensionField: isNumberDrilldownDimension,
        templateId: currentDrilldownTemplateId,
        secondaryMetricOptions: secondaryMetricEntry,
        showRange: true,
        viewEntry,
        onDataLoading,
        isChartAndTotalLoading,
        viewMode: VIEW_MODE.SMALL,
        currentSnapshotView: defaultChartView,
        ignoreCompareDateRange: true,
        cardImageId
    };
    const pieChartAttributes = {
        apiParams: getQueryParams(propsOption),
        viewEntry: viewEntry,
        isEmbed,
        isCurrencyDimensionField,
        templateId: currentDrilldownTemplateId,
        viewMode: VIEW_MODE.SMALL,
        currentSnapshotView: defaultChartView,
        onDataLoading: onDataLoading,
        isChartAndTotalLoading,
        drillDownTotal: currentDimensionTotal,
        ignoreCompareDateRange: true,
        cardImageId
    };

    return {
        barAttributes,
        scatterChartAttributes,
        pieChartAttributes,
        defaultChartView
    }
}

export const getOvertimeAttributes = (propsOption) => {
    const {
        drilldownEntry,
        viewEntry,
        commonFilters,
        visualizationType,
        visualizationEntry,
        cardImageId,
        isBookmarkCard
    } = propsOption;
    const {
        currentDrilldownTemplateId,
        currentDrilldownDimensionField,
        currentDrilldownViewEntry,
    } = drilldownEntry;

    const dateRange = _.get(commonFilters, 'dateRange', {});
    const dateRangeMode = _.get(commonFilters, 'dateType', getDefaultDateMode());
    const comparisonModeOn = _.get(commonFilters, 'comparisonModeOn', false);
    const chartType = getCurrentVizChartView(propsOption);
    const isComboChart = isComboChartEnable(viewEntry, chartType);
    const canShowProjection =
        canShowProjectionMetric(viewEntry, chartType) &&
        isAboveConfiguredEndPeriod(_.get(dateRange, 'endDate'), 'day');
    const overtimeVisualizationEntry = _.get(
        visualizationEntry,
        'overtime',
        {}
    );
    let secondaryMetricEntry = getDefaultSecondaryMetricEntry(
        viewEntry,
        'overtime',
        chartType
    );
    let benchMarkEntries = getDefaultBenchMarkEntries(
        viewEntry,
        visualizationType,
        chartType,
        currentDrilldownTemplateId,
        currentDrilldownDimensionField
    );

    let isProjectionEnabled = canShowProjection
        ? isShowProjectionDefault(viewEntry, chartType)
        : false;
    let dimensionConfigsByRenderType = {};
    const currentForecastOption = _.get(visualizationEntry, [
        visualizationType,
        'currentForecastOption',
    ]);
    const currentProjectionType = getDefaultProjectionMetric(
        viewEntry,
        currentForecastOption
    );
    const defaultAxisGranularity = getDefaultAxisGranularity(currentDrilldownTemplateId);
    let axisGranularity = _.get(
        overtimeVisualizationEntry,
        'axisGranularity',
        defaultAxisGranularity
    );
    if (showAllYears()) {
        axisGranularity = _.includes(['month', 'year'], axisGranularity) ?
            axisGranularity :
            defaultAxisGranularity;
    }
    const sliderRange = _.get(overtimeVisualizationEntry, 'sliderRange', []);

    if (!_.isEmpty(overtimeVisualizationEntry)) {
        secondaryMetricEntry = getSecondaryMetricEntry(
            viewEntry,
            'overtime',
            chartType,
            overtimeVisualizationEntry.currentSecondaryMetricField
        );

        const benchMarkMetricNames =
            overtimeVisualizationEntry.currentBenchMarkMetricNames;
        benchMarkEntries = getBenchMarkMetricEntries(
            viewEntry,
            'overtime',
            chartType,
            benchMarkMetricNames
        );
        isProjectionEnabled = canShowProjection
            ? overtimeVisualizationEntry.isShowProjection
            : false;
        const dimensionConfigs = _.get(
            overtimeVisualizationEntry,
            'dimensionConfigsByRenderType',
            {}
        );
        dimensionConfigsByRenderType = _.get(
            overtimeVisualizationEntry,
            `dimensionConfigsByRenderTyp.${chartType}`,
            dimensionConfigs
        );
    }

    const templateCardKey =
        viewEntry.name + JSON.stringify(_.get(commonFilters, 'dateRange', {}));
    const drilldownWithoutViewEntry = getDrilldownEntryWithoutViewEntry(
        drilldownEntry, visualizationType
    );

    let compareYearRanges = (chartType == OVERTIME_VISUALIZATION_TYPES.AREA.type) ? [] :
        getDefaultCompareYearRange(viewEntry, commonFilters, currentDrilldownTemplateId);
    if(isBookmarkCard){
        compareYearRanges = comparisonModeOn ? getComparisonPeriodDateRanges(commonFilters) : [];
    }
    const newCommonFilters = {
        ...commonFilters,
        comparisonDateRanges: compareYearRanges
    }
    const currentTimeFrameOption = _.isEmpty(compareYearRanges) || isComboChart ?
        OVERTIME_TIME_FRAME_OPTIONS.ROLLING : OVERTIME_TIME_FRAME_OPTIONS.YEAR_ON_YEAR;
    const dimension_limit = showOvertimeChartDimensions(currentDrilldownViewEntry, chartType) ?
        defaultNumberOfdimensions(currentDrilldownViewEntry, chartType) : CHART_LIMIT;
    const sortOrderDimensionHighToLow =  showOvertimeChartDimensions(currentDrilldownViewEntry, chartType) &&
    isDimensionSortOrderHightToLow(currentDrilldownViewEntry, chartType);

    const apiParams = {
        axisGranularity,
        drilldownEntry: getDrilldownEntryForSnapshot(JSON.stringify(drilldownWithoutViewEntry)),
        commonFilters: JSON.stringify(sanitizeCommonFilterParam(newCommonFilters)),
        secondaryMetricField: _.get(secondaryMetricEntry, 'field'),
        currentChartType: chartType,
        limit: dimension_limit,
        currentSelectedTimeFrame: currentTimeFrameOption,
        sortOrderDimensionHighToLow,
        compareYearRanges: JSON.stringify(compareYearRanges),
    };


    const currentDrilldownDimension = getSelectedDimensionEntry(
        currentDrilldownTemplateId,
        currentDrilldownDimensionField
    );
    const isCurrencyDimensionField = _.get(currentDrilldownDimension, 'renderType') === CURRENCY_TYPE;

    return {
        templateCardKey,
        isCurrencyDimensionField,
        axisGranularity,
        apiParams,
        dateRangeMode,
        viewEntry,
        viewMode: VIEW_MODE.SMALL,
        benchMarkEntries,
        isChartAndTotalLoading: propsOption.isChartAndTotalLoading,
        isComboChart,
        templateId: currentDrilldownTemplateId,
        secondaryMetricEntry,
        sliderRange,
        chartType,
        renderTimeFrame: currentTimeFrameOption,
        dateRange,
        compareToRanges: compareYearRanges,
        compareYearRanges: compareYearRanges,
        projectionEnabled: isProjectionEnabled,
        projectionType: currentProjectionType,
        dimensionConfigsByRenderType,
        isDimensionHighToLow: sortOrderDimensionHighToLow,
        cardImageId
    }
}

export const getDistributionAttributes = (propsOption) => {
    const {
        drilldownEntry,
        viewEntry,
        commonFilters,
        isChartAndTotalLoading,
        visualizationEntry,
        cardImageId
    } = propsOption;
    const templateId = _.get(drilldownEntry, 'currentDrilldownTemplateId');
    const quickFilters = _.get(drilldownEntry, 'quickFilters', []);
    const dimensionField = _.get(
        drilldownEntry,
        'currentDrilldownDimensionField'
    );
    const dimensionColumn = getCurrentDrilldownDimensionColumn(
        templateId,
        dimensionField
    );
    const drilldownWithoutViewEntry = getDrilldownEntryWithoutViewEntry(
        drilldownEntry
    );

    const apiParams = {
        drilldownEntry: JSON.stringify(drilldownWithoutViewEntry),
        commonFilters: JSON.stringify(sanitizeCommonFilterParam(commonFilters)),
    };
    const currentBenchMarkMetricNames = _.get(
        visualizationEntry,
        'distribution.currentBenchMarkMetricNames',
        []
    );
    const defaultDistributionOptions = getInitialDistributionOptions(
        viewEntry,
        visualizationEntry
    );
    const currentDrilldownDimension = getSelectedDimensionEntry(templateId, dimensionField);
    const isCurrencyDimensionField = _.get(currentDrilldownDimension, 'renderType') === CURRENCY_TYPE;

    return {
        apiParams,
        isCurrencyDimensionField,
        viewEntry,
        quickFilters,
        dimensionColumn,
        viewMode: VIEW_MODE.SMALL,
        defaultDistributionOptions,
        isChartAndTotalLoading,
        templateId,
        benchmarkMetricNames: currentBenchMarkMetricNames,
        cardImageId
    }
}

// Vendor imports
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

// Project Imports
import { LAYERS as POINT_AND_STACK_LAYERS } from '../partials/PointsAndStackPartial';
import { LAYERS as CHOROPLETH_LAYERS } from '../partials/ChoroplethMapPartial';
import { LAYERS as SHAPE_FILTER_LAYERS } from '../partials/ShapeFilterPartial';
import { LAYERS as SEARCH_LAYERS } from '../searchPartials/SearchPointsAndStackPartial';
import { LAYERS as SEARCH_SUBJECT_LAYERS } from '../searchPartials/SearchSubjectPoints';
import { getFormattedValue } from 'helpers/chartDataHelper';
import { getNearestCoordinates, getCoordinatesFromAddress,
  formatAddressForGoogleService } from 'helpers/GoogleMapServicesHelper';
import {
  getCompFinderIcon,
  getMapFlyoutEntries,
  getShapeMapFlyoutEntries,
  getTableColumnEntries
} from 'common/config/templateConfiguration';
import { getGoogleStreetViewMapKey } from 'common/config/customerConfiguration';
import { CURRENCY_TYPE, SEARCH_METHOD } from 'appConstants';
import { getFlyoutDetails, fetchShapeFlyoutEntries } from 'common/api/drilldown';
import { addhttp } from 'helpers/HttpHelper';
import { formatValueToCurrency, getAbbreviatedStringValue } from 'helpers/numberHelper';
import GlobalEvent from 'common/components/GlobalEvents';
import PopupFactory from './PopupFactory';
import { getSecondsAsDuration } from 'helpers/visualizationHelper';
import { isAddressConfigured } from 'common/config/templateConfiguration';
import { getMapFlyoutData } from 'common/api/advanceSearchApi';
import { formattedValueByDataType } from 'modules/DetailsTable/TableHelper';
import { buildQueryString } from 'helpers/HttpHelper';
import { isValidPdfColumn } from 'modules/AdvanceSearch/advanceSearchHelper';
import MapPopupPosition from './MapPopupPosition';
import {
  calculateTransformValue,
  getNewAnchorPosition,
  isMarkerOnCenterOfMap, updatePopupAnchorClass
} from './MapPopupPositionHelper';

const HELP_TEXT_CONTENT = "Click the data point icon to keep this flyout open <br />"+
  "and view the full record.";
export default class PopupHandler {
  constructor(map, options, onRowIdsSelect) {
    this._map = map;
    this._options = options;
    this._callBack = onRowIdsSelect;
    this._featurePopup = PopupFactory.create();
    this._featurePopupGeometries = null;
    this._spiderMarkerPersistPopup = null;
    this._choroplethData = [];
    this._currentDrilldownViewEntry = {};
    this._updateStreetMapCoordinates = options.updateStreetMapCoordinates;
    this._showNoteModel = options.showNoteModel;
  }

  // Initializes and attaches popup to markers in spider legs. On mouseover
  // shows popup and on mouse leave hides the popup.
  initializePopupForSpideredMarker = async (spiderLeg) => {
    let geoCoordinates = _.get(spiderLeg.feature, 'geometry.coordinates'),
    feature = _.get(spiderLeg.feature, '', {}), coordinates, address, googleCoordinates;
    const drilldownEntry = JSON.parse(_.get(this._options, 'apiParams.drilldownEntry', {}));
    const templateId = _.get(drilldownEntry, 'currentDrilldownTemplateId');

    if (_.get(spiderLeg.feature, 'type') === 'FeatureCollection') {
      geoCoordinates = _.get(spiderLeg.feature, 'features.[0].geometry.coordinates');
      feature = _.get(spiderLeg.feature, 'features.[0]', {});
    }

    if (isAddressConfigured(templateId)) {
      address = formatAddressForGoogleService(feature, templateId);
      googleCoordinates = await getCoordinatesFromAddress(address, geoCoordinates);
      coordinates = await getNearestCoordinates(googleCoordinates);
    } else {
      coordinates = await getNearestCoordinates(geoCoordinates);
    }
    const isStreetViewEnabled = !_.isEmpty(coordinates);
    const spiderMarkerPopup = PopupFactory.create(spiderLeg.param.x, spiderLeg.param.y);
    const mapPopupPosition = new MapPopupPosition(this._map, this._options, spiderMarkerPopup, spiderLeg);

    $(spiderLeg.elements.container).
      on('mouseenter', async () => {
        spiderLeg.mapboxMarker.setPopup(spiderMarkerPopup);
        spiderMarkerPopup.addTo(this._map);
        await this._setSpiderfyPointPopupContent(
          spiderMarkerPopup, [spiderLeg.feature], isStreetViewEnabled, coordinates);

        mapPopupPosition.adjustPopupPosition();
      }).
      on('mouseleave', () => {
        if (this._spiderMarkerPersistPopup !== spiderMarkerPopup) {
          spiderMarkerPopup.remove();
        }
      }).
      on('click', (event) => {
        this.getMapFeaturePointForLeafPage(spiderLeg.feature);
        const spiderCircle = $(spiderLeg.elements.container).find('.spider-point-circle');
        if (!_.isNil(this._spiderMarkerPersistPopup)) {
          this._spiderMarkerPersistPopup.remove();
          this._spiderMarkerPersistPopup = null;
        }
        if (!_.isNil(this._previousSpiderCircle)) {
          this._previousSpiderCircle.toggleClass('spider-leg-hover');
        }
        spiderCircle.addClass('spider-leg-hover');

        spiderMarkerPopup.addTo(this._map);
        this._spiderMarkerPersistPopup = spiderMarkerPopup;
        mapPopupPosition.onUpdatePopup();
        this._previousSpiderCircle = spiderCircle;
        event.stopPropagation();
      });
  }

  getMapFeaturePointForLeafPage = (feature) => {
    const rowId =_.get(feature, 'properties.row_id_key', '');
    GlobalEvent.emit('MAP_LEAFPAGE_DATA_ID', rowId);
  }

  destroyPopupForSpideredMarker = (spiderLeg) => {
    $(spiderLeg.elements.container).off();
  }

  showFeaturePopup = async (event, features) => {
    const layerId = _.get(features[0], 'layer.id');
    const featuresGeometry = _.map(features, 'geometry');
    const isAdvancedSearch = _.get(this._options, 'isAdvancedSearch', false);

    let coordinates;

    if (
        layerId === CHOROPLETH_LAYERS.SHAPES_FILL ||
        layerId === SHAPE_FILTER_LAYERS.SHAPE_FILTER_LAYER ||
        layerId === SHAPE_FILTER_LAYERS.SHAPE_FILTER_HIGHLIGHT
      ) {
      coordinates = _.get(event, 'lngLat');
    } else {
      coordinates = _.get(features[0], 'geometry.coordinates');
    }

    // To prevent recalculating and showing popup, when a popup is already shown for the
    // given feature.
    if (_.isEqual(this._featurePopupGeometries, featuresGeometry)) {
      return;
    }

    const popupContentElement = document.createElement('div');
    this._featurePopup.setLngLat(coordinates);
    this._featurePopup.setDOMContent(popupContentElement);
    this._featurePopup.addTo(this._map);
    await this._setPopContent(features, popupContentElement);
    // Here we re-position the fly-out in map. If the point is top of the map.
    this._featurePopup.addTo(this._map);

    if(isStackLayer(layerId)) {
      $(this._featurePopup._content).closest('.mapboxgl-popup').addClass('stack-count');
    } else {
      $(this._featurePopup._content).closest('.mapboxgl-popup').removeClass('stack-count');
    }

    if(isAdvancedSearch){
      const isOnCentreLayer = isMarkerOnCenterOfMap(this._map,this._featurePopup);
      if(isOnCentreLayer){
        const newAnchor = getNewAnchorPosition(this._map,this._featurePopup);
        updatePopupAnchorClass(this._featurePopup,newAnchor);
        const transformValue = calculateTransformValue(this._featurePopup, newAnchor);
        $(this._featurePopup._content).closest('.mapboxgl-popup').css('transform', transformValue);
      }
    }

    this._featurePopupGeometries = featuresGeometry;
  }

  removeFeaturePopup = () => {
    this._featurePopupGeometries = null;
    this._featurePopup.remove();
  }

  spiderPersistPopupRemove() {
    if (!_.isNil(this._spiderMarkerPersistPopup)) {
      this._spiderMarkerPersistPopup.remove();
      this._spiderMarkerPersistPopup = null;
    }
  }

  updateApiParams(apiParams) {
    this._options = _.merge({}, this._options, { apiParams });
  }

  updateAdvanceSearchParams(advanceSearchParams) {
    this._options = _.assign({}, this._options, advanceSearchParams);
  }

  updateChoroplethData(choroplethData, currentDrilldownViewEntry) {
    this._choroplethData = choroplethData;
    this._currentDrilldownViewEntry = currentDrilldownViewEntry;
  }

  _setPopContent = async (features, popupContentElement) => {
    let coordinates, geoCoordinates = features[0]._geometry.coordinates;
    const layerId = _.get(features[0], 'layer.id');

    if (
      layerId === POINT_AND_STACK_LAYERS.STACKS_CIRCLE ||
      layerId === POINT_AND_STACK_LAYERS.STACKS_LABEL ||
      layerId === SEARCH_LAYERS.STACKS_CIRCLE ||
      layerId === SEARCH_LAYERS.STACKS_LABEL
      ) {
      this._setStockPopupContent(popupContentElement, features);
    } else if (
      layerId === POINT_AND_STACK_LAYERS.INCIDENT_CIRCLE ||
      layerId === POINT_AND_STACK_LAYERS.INCIDENT_ICON ||
      layerId === SEARCH_LAYERS.INCIDENT_SELECT_ICON ||
      layerId === SEARCH_LAYERS.INCIDENT_UNSELECT_ICON ||
      layerId === SEARCH_SUBJECT_LAYERS.INCIDENT_ICON
      )
    {
      const drilldownEntry = JSON.parse(_.get(this._options, 'apiParams.drilldownEntry', {}));
      const templateId = _.get(drilldownEntry, 'currentDrilldownTemplateId');
      if (isAddressConfigured(templateId)) {
        const address = formatAddressForGoogleService(features, templateId);
        const googleCoordinates = await getCoordinatesFromAddress(address, geoCoordinates);
        coordinates = await getNearestCoordinates(googleCoordinates);
      } else {
        coordinates = await getNearestCoordinates(geoCoordinates);
      }
      const isStreetViewEnabled = !_.isEmpty(coordinates);
      await this._setPointPopupContent(popupContentElement, features, isStreetViewEnabled);
    } else if (layerId === CHOROPLETH_LAYERS.SHAPES_FILL) {
      this._setChoroplethPopupContent(popupContentElement, features);
    } else if (
      layerId === SHAPE_FILTER_LAYERS.SHAPE_FILTER_LAYER ||
      layerId === SHAPE_FILTER_LAYERS.SHAPE_FILTER_HIGHLIGHT
      ) {
      this._setShapeFilterPopupContent(popupContentElement, features);
    }

    $(popupContentElement).find('.street-view-button').on('click',() => {
      this._updateStreetMapCoordinates(coordinates);
    });

    $(popupContentElement).find('.notes-button').on('click',(evt) => {
      this._showNoteModel(evt.currentTarget.getAttribute('noteId'));
    });

    $(popupContentElement).find('#report-checkbox').on('click',(evt) => {
      const isChecked = evt.target.checked;
      const rowId = evt.currentTarget.getAttribute('row-id');
      this._updateAdvanceSearchCompareReport(isChecked,rowId);
    });

    $(popupContentElement).find('.icons-close').on('click',() => {
      this._featurePopup.remove();
    });

  }

  _setStockPopupContent = (popupContentElement, features) => {
    const incidentCount = _.get(features[0].properties, 'count');

    $(popupContentElement).html('<div class="choropleth-map-popup">' +
      `<div class="popup-title">${incidentCount} Counts</div>` +
      '</div>');
  }

  _setPointPopupContent = async(popupContentElement, features, isStreetViewEnabled) => {
    $(popupContentElement).html(getLoadingSpinnerContent());
    const popupContent = await this._getPopupContent(features, isStreetViewEnabled);
    $(popupContentElement).html(popupContent);
  }

  _getPopupContent = async(features, isStreetViewEnabled) => {
    const isAdvancedSearch = _.get(this._options, 'isAdvancedSearch', false);
    if(isAdvancedSearch){
      return this._getPopupContentForPointInAdvanceSearch(features, isStreetViewEnabled);
    }else{
      return this._getPopupContentForPoint(features, isStreetViewEnabled);
    }
  }

  _updateAdvanceSearchCompareReport = async (isChecked,rowId) => {
    const { reportPageData, dispatchUpdateReportPageData, advanceSearchMethod,
      dispatchUpdateSelectedReportIds, advanceSearchTemplateId, subjectSearchField } = this._options;

    const data = advanceSearchMethod == SEARCH_METHOD.CUSTOM ?
    await getReportFlyoutDetails(rowId, advanceSearchTemplateId) :
    await getSubjectFlyoutDetails(rowId, advanceSearchTemplateId, subjectSearchField);

    const reportData = data[0];

    let selectedResults = _.isEmpty(reportPageData) ? [] : [...reportPageData]
    if (isChecked) {
      selectedResults = [...selectedResults, reportData];
    } else {
      selectedResults = _.filter(selectedResults,
        (selectedItem) => selectedItem['row_id_field'] !== reportData['row_id_field']
      );
    }
    dispatchUpdateReportPageData(selectedResults);
    const selectedRowIds = _.map(selectedResults, 'row_id_field')
    dispatchUpdateSelectedReportIds(selectedRowIds);
    this._callBack(selectedRowIds);
  }

  _getAdvancedSearchHeaderFlyoutDetails(flyoutEntries, flyoutDetails) {
    const { advanceSearchTemplateId, advanceSearchMethod } = this._options;
    if(_.isEmpty(flyoutDetails) && _.isEmpty(flyoutEntries)){
      return '';
    }

    const flyoutHeaderValue1 = _.get(flyoutDetails[0], flyoutEntries[0].field, null);
    let flyoutHeaderValue2 = "";
    if(_.size(flyoutEntries) > 1) {
      const flyoutValue = _.get(flyoutDetails[0], flyoutEntries[1].field, null);
      flyoutHeaderValue2 = `${flyoutEntries[1].name} - ${flyoutValue}`
    }

    let iconName = getCompFinderIcon(advanceSearchTemplateId, advanceSearchMethod);
    iconName =  !_.isEmpty(iconName) ? `./images/${iconName}` : '';

    const compFinderIcon = !_.isEmpty(iconName) ? `<img src=${iconName} alt='house-appraisal-spot' />` : '';

    const headerDetails = (
      `<div class="d-flex gap-10">`+
        `${compFinderIcon}` +
        `<div>`+
          `<div class="entity-head-1">${flyoutHeaderValue1}</div>` +
          `<div class="entity-head-2">${flyoutHeaderValue2}</div>` +
        `</div>`+
        `<div class="close-btn pt-1">`+
          `<i class="icons-close"></i>` +
        `</div>`+
      `</div>`
    );

    return(
      `<div class="header-details">${headerDetails}</div>`
    );
  }

  _getPopupContentForPointInAdvanceSearch = async(features, isStreetViewEnabled) => {
    const { advanceSearchTemplateId, advanceSearchMethod, subjectSearchField, } = this._options;

    let popupContent = '', popupDetails = '';

    const rowId =_.get(features[0], 'properties.row_id_key', '');

    const flyoutEntries = getTableColumnEntries(advanceSearchTemplateId);

    try {

      const flyoutDetails = advanceSearchMethod == SEARCH_METHOD.CUSTOM ?
        await getReportFlyoutDetails(rowId, advanceSearchTemplateId) :
        await getSubjectFlyoutDetails(rowId, advanceSearchTemplateId, subjectSearchField);

      const headerFlyoutContent = this._getAdvancedSearchHeaderFlyoutDetails(flyoutEntries,
        flyoutDetails);
      const additionalFlyoutContent =
        getAdvancedSearchFlyoutDetails(
          flyoutEntries, flyoutDetails, advanceSearchTemplateId, advanceSearchMethod
        );
      const propertyPhoto = getPropertyPhoto(_.first(flyoutDetails));
      const addToCompReportCheckBox = addToCompReport(rowId, this._options);

      if(!_.isEmpty(additionalFlyoutContent)){
        popupDetails = '<div class="popup-details">' +
          `<div class="popup-header">` +
          headerFlyoutContent +
          propertyPhoto+
          '</div>' +
          additionalFlyoutContent +
        '</div>'
      }

      popupContent = '<div class="advance-search-flyout">'+
        popupDetails +
        '<div class="pl-3 pb-2">' +
        addToCompReportCheckBox +
        (getAdvanceSearchStreetViewLink(isStreetViewEnabled)) +
        '</div>'+
      '</div>';
    } catch (e) {
      console.log('point hover feature error', e); // eslint-disable-line no-console
    }

    return popupContent;
  }

  _getPopupContentForPoint = async(features, isStreetViewEnabled) => {
    let popupContent = '', popupDetails = '';
    const isCurrencyDimensionField = _.get(this._options, 'apiParams.isCurrencyDimensionField', false);
    let dimension = formatValueToCurrency(
      _.get(features[0], 'properties.dimension', ''),
      isCurrencyDimensionField
    );
    const rowId =_.get(features[0], 'properties.row_id_key', '');
    const drilldownEntry = JSON.parse(_.get(this._options, 'apiParams.drilldownEntry', {}));
    const commonFilters = JSON.parse(_.get(this._options, 'apiParams.commonFilters', {}));
    const templateId = _.get(drilldownEntry, 'currentDrilldownTemplateId');
    const ignore_view_entry = _.get(this._options, 'apiParams.ignore_view_entry', false);
    const flyoutEntries = getMapFlyoutEntries(templateId);

    try {
      const params = {
        drilldownEntry: JSON.stringify(drilldownEntry),
        commonFilters: JSON.stringify(commonFilters),
        row_id_field: rowId,
        ignore_view_entry
      };

      const flyoutDetails = await getFlyoutDetails(params);
      const flyOutNoteLink = _.isEmpty(flyoutDetails) ? '' : getNoteLink(flyoutDetails);
      const additionalFlyoutContent = _.isEmpty(flyoutDetails) ?
        '' :
        getAdditionalFlyoutContent(flyoutEntries, flyoutDetails, false, ignore_view_entry);

      if(ignore_view_entry) {
        const flyoutEntry = _.get(flyoutEntries, '0', {});
        const flyoutValue = _.get(flyoutDetails[0], flyoutEntry.field, '');
        dimension = flyoutValue;
      }

      if(!_.isEmpty(dimension) || !_.isEmpty(additionalFlyoutContent)){
        const popupHeadClassNames = _.isEmpty(additionalFlyoutContent) ? " border-bottom-0" : '';
        popupDetails = '<div class="popup-details">' +
          `<div class="popup-head${popupHeadClassNames}">` +
            `<span class='ml-1'>${dimension}</span>` +
          '</div>' +
          additionalFlyoutContent +
        '</div>'
      }

      popupContent = '<div>'+
         popupDetails +
        '<div class="popup-footer">' +
          flyOutNoteLink +
          // (ignore_view_entry ? '' : getStreetViewLink(isStreetViewEnabled)) +
          (getStreetViewLink(isStreetViewEnabled)) +
        '</div>'+
        `<div class='popup-help-text'>${HELP_TEXT_CONTENT}</div>`+
      '</div>';
    } catch (e) {
      console.log('point hover feature error', e); // eslint-disable-line no-console
    }

    return popupContent;
  }

  _setSpiderfyPointPopupContent =
    async(spiderMarkerPopup, featureCollection, isStreetViewEnabled, coordinates) => {
    spiderMarkerPopup.setHTML(getLoadingSpinnerContent());
    const popupContent = await this._getPopupContent(featureCollection, isStreetViewEnabled);

    spiderMarkerPopup.setHTML(popupContent);

    $(spiderMarkerPopup._content).find('.street-view-button').on('click',() => {
      this._updateStreetMapCoordinates(coordinates);
    });

    $(spiderMarkerPopup._content).find('.notes-button').on('click',(evt) => {
      this._showNoteModel(evt.currentTarget.getAttribute('noteId'));
    });

    $(spiderMarkerPopup._content).find('#report-checkbox').on('click',(evt) => {
      const isChecked = evt.target.checked;
      const rowId = evt.currentTarget.getAttribute('row-id');
      this._updateAdvanceSearchCompareReport(isChecked,rowId);
    });

    $(spiderMarkerPopup._content).find('.icons-close').on('click',() => {
      spiderMarkerPopup.remove();
    });
  }

  _setChoroplethPopupContent = async(popupContentElement, features) => {
    // TODO: Need to remove height if click and max-height is set to the flyout.
    $(popupContentElement).html('<div style="height: 150px;">'+
        getLoadingSpinnerContent('choropleth')+
      '</div>'
    );
    const { isJenks, valueConfigs } = this._choroplethData;
    let shapeValue = _.get(features[0].properties, 'count');
    const shapeId = _.get(features[0].properties, 'shape_id');
    const shapeGroupId = _.get(features[0].properties, 'shape_group_id');

    if (isJenks) {
      const shapeDetails = _.find(valueConfigs, (config) => {
        return config['shape_id'] == shapeId;
      });
      shapeValue = _.get(shapeDetails, 'value');
    }
    const shapeName = _.get(features[0].properties, 'shape_name', '');
    let formatedValue;

    if (_.isNil(shapeValue)) {
      formatedValue = 'N/A';
    } else {
      formatedValue = getFormattedValue(shapeValue, this._currentDrilldownViewEntry);
    }
    const drilldownEntry = JSON.parse(_.get(this._options, 'apiParams.drilldownEntry', {}));
    const templateId = _.get(drilldownEntry, 'currentDrilldownTemplateId');
    const viewField = _.get(drilldownEntry, 'currentViewEntryField');
    let flyoutEntries = [];
    flyoutEntries.push(this._currentDrilldownViewEntry);
    flyoutEntries.push(getShapeMapFlyoutEntries(templateId, shapeGroupId));
    flyoutEntries = _.flatten(flyoutEntries);
    const params = {
      drilldownEntry: JSON.stringify(drilldownEntry),
      shapeGroupId,
      shape_id: shapeId
    };

    let flyoutDetails = await fetchShapeFlyoutEntries(params);
    const viewDetails = [{ [viewField]: formatedValue }];
    flyoutDetails = _.merge(flyoutDetails, viewDetails);
    const popupHeadClassNames = _.isEmpty(flyoutDetails) ? " border-bottom-0" : '';
    const additionalFlyoutContent = _.isEmpty(flyoutDetails) ?
      '' :
      getAdditionalFlyoutContent(flyoutEntries, flyoutDetails, true);

    $(popupContentElement).html('<div class="choropleth-map-popup popup-details">' +
      `<div class="popup-head${popupHeadClassNames}"><span>${shapeName}</span></div>` +
      additionalFlyoutContent+
      '</div>');
  }

  _setShapeFilterPopupContent = (popupContentElement, features) => {
    const shapeName = _.get(features[0].properties, 'shape_name', '');

    $(popupContentElement).html('<div class="choropleth-minmap-popup popup-details">' +
      `<div class="popup-head border-bottom-0">${shapeName}</div>` +
      '</div>'
    );
  }
}

function addToCompReport(rowId, options) {
  const { selectedReportRowIds, subjectData } = options;
  const isCheckboxChecked = _.includes(selectedReportRowIds, rowId);
  const isCheckboxDisabled =  _.get(subjectData, 'row_id_field','') === rowId ||
    (!_.includes(selectedReportRowIds, rowId) && _.size(selectedReportRowIds) >= 5);

  const checkboxDetails = (
    `<forge-checkbox dense="true">`+
      `<input type="checkbox"
        id="report-checkbox"
        row-id="${rowId}"
        ${isCheckboxChecked ? "checked" : ""}
        ${isCheckboxDisabled ? "disabled" : ""}
      />`+
      `<label class="checkbox-label m-0" for="report-checkbox">Add to Comp Report</label>`+
    `</forge-checkbox>`
  );

  return(
    `<div class="compare-checkbox">${checkboxDetails}</div>`
  );

}

function getStreetViewLink(isStreetViewEnabled) {
  if (_.isEmpty(getGoogleStreetViewMapKey())) {
   return '';
  }
  return '<div class="street-view-footer '+(isStreetViewEnabled ? '' : 'disable')+'">' +
      '<div class="pull-right street-view-button"> Street View </div>' +
    '</div>';
}

function getAdvanceSearchStreetViewLink(isStreetViewEnabled) {
  if (_.isEmpty(getGoogleStreetViewMapKey())) {
   return '';
  }
  return '<div class="advance-search-street-view '+(isStreetViewEnabled ? '' : 'disable')+'">' +
      '<div class="street-view-button pl-1 pt-2"> Street view </div>' +
    '</div>';
}

function getNoteLink(flyoutDetails) {
  if (_.isEmpty(_.first(flyoutDetails)['parent_dataset_join_column'])) {
   return '';
  }
  const noteId = _.first(flyoutDetails)['parent_dataset_join_column'];

  return '<div>' +
    '<button class="btn btn-outline-primary btn-sm notes-button" noteId="'+noteId+'"> Note </btn>' +
  '</div>';
}

function getLoadingSpinnerContent(className) {
  return `<div class='loading-spinner-container ${className}'>` +
    '<div class="loading-spinner"></div>' +
    '</div>';
}

function formatFlyoutValue(value, flyoutEntry) {
  const renderType = _.get(flyoutEntry, 'render_type', '');

  if(_.isEqual(renderType, 'url')){
    const url = _.isObject(value) ? _.get(value, 'url', '') : value;
    const formattedUrl = addhttp(url);
    return (`<a href=${formattedUrl} target="_blank"> ${url}</a>`);
  } else if(renderType === CURRENCY_TYPE){
    return formatValueToCurrency(value, true);
  } else if(renderType === 'amount'){
    const valueAmount = (_.isUndefined(value) || _.isNull(value)) ? 0 : value;
    const formattedValue = `$${getAbbreviatedStringValue(valueAmount, null, false, false)}`;
    return formattedValue;
  } else if(renderType === 'date'){
    return moment(value).format('LL [at] LT');
  } else if(renderType === 'duration'){
    const dataUnit = _.get(flyoutEntry, 'data_unit', 'seconds');
    return getSecondsAsDuration(value, dataUnit);
  } else {
    return value;
  }
}

function getAdditionalFlyoutContent(flyoutEntries, flyoutDetails, isChoroplethMap, ignoreFirstEntry = false) {
  const tbodyContent = _.map(flyoutEntries, (flyoutEntry, index) => {
    const flyoutValue = _.get(flyoutDetails[0], flyoutEntry.field, null);
    if(index === 0 && ignoreFirstEntry) {
      return;
    }
    if(_.isNull(flyoutValue)) {
      return;
    }
    const formattedFlyoutValueContent = getEntryValues(flyoutEntry, flyoutDetails, isChoroplethMap);

    return (
      "<div class='entity-properties'>" +
        `<div class="entity-field">${flyoutEntry.name}</div>` +
        `<div class="entity-value">${formattedFlyoutValueContent}</div>` +
      '</div>'
    );
  });

  return `<div class="popup-inner">${tbodyContent.join('')}</div>`;
}

function getAdvancedSearchFlyoutDetails(flyoutEntries, flyoutDetails, templateId, searchMethod) {
  if(_.isEmpty(flyoutDetails)){
    return '';
  }
  const isSubjectSearch = SEARCH_METHOD.SUBJECT_PROPERTY == searchMethod;
  const tbodyContent = _.map(flyoutEntries, (flyoutEntry, index) => {
    const flyoutValue = _.get(flyoutDetails[0], flyoutEntry.field, null);
    if(_.isNull(flyoutValue)) {
      return;
    }

    const formattedFlyoutValueContent = getAdvanceSearchEntryValues(flyoutEntry, flyoutDetails);
    const isValidColumn = isValidPdfColumn(isSubjectSearch, templateId, index, flyoutEntry, flyoutDetails[0])
    const formattedFlyoutValue = isValidColumn ? getPropertyPdf(flyoutDetails[0], formattedFlyoutValueContent)
      : formattedFlyoutValueContent;

    return (
      "<div class='entity-properties'>" +
        `<div class="entity-field">${flyoutEntry.name}</div>` +
        `<div class="entity-value">${formattedFlyoutValue}</div>` +
      '</div>'
    );
  });

  return `<div class="popup-inner">${tbodyContent.join('')}</div>`;
}

function getPropertyPhoto(flyoutDetails) {
  const countryName = _.get(flyoutDetails, 'comp_finder_country', '').replace(/County/i, "").trim();
  if(_.isEmpty(countryName) || !_.get(flyoutDetails, 'is_property_details_available', false)){
    return '';
  }

  const queryParams = {
    country: countryName,
    quickRefId: _.get(flyoutDetails, 'comp_finder_quick_ref_id', ''),
    taxYear: _.get(flyoutDetails, 'comp_finder_tax_year', '')
  };
  const imgSrc = `/api/advanced_search/get_external_image.png?${buildQueryString(queryParams)}`;

  return (
    "<div class='external_image'>" +
      `<img src=${imgSrc}></img>` +
    "</div>"
  );
}

function getPropertyPdf(flyoutDetails, value) {
  if(_.isEmpty(flyoutDetails)){
    return '';
  }

  const queryParams = {
    country: _.get(flyoutDetails, 'comp_finder_country', '').replace(/County/i, "").trim(),
    quickRefId: _.get(flyoutDetails, 'comp_finder_quick_ref_id', ''),
    taxYear: _.get(flyoutDetails, 'comp_finder_tax_year', '')
  };
  const href = `/api/advanced_search/get_external_pdf.pdf?${buildQueryString(queryParams)}`;
  return (
    `<a href=${href} target='_blank' rel='noopener noreferrer'>` +
      value +
    "</a>"
  )

}

function getAdvanceSearchEntryValues(flyoutEntry, flyoutDetails) {
  let content = '';
  let newDetails = [];
  if(_.isEmpty(flyoutEntry['column_dataset'])){
    newDetails = [flyoutDetails[0]];
  }else {
    newDetails = flyoutDetails;
  }
  _.each(newDetails, (entry) => {
    const flyoutValue = _.get(entry, flyoutEntry.field, '');
    let formattedFlyoutValue = !_.isEmpty(flyoutValue) ?
      formattedValueByDataType(flyoutValue, flyoutEntry, true) : flyoutValue;
    if(_.isObject(formattedFlyoutValue)){
      formattedFlyoutValue = _.get(formattedFlyoutValue,'url','');
    }
    content += `<div>${formattedFlyoutValue} </div>`;
  });
  return content;
}

function getEntryValues(flyoutEntry, flyoutDetails, isChoroplethMap) {
  let content = '';
  let newDetails = [];
  if(_.isEmpty(flyoutEntry['column_dataset'])){
    newDetails = [flyoutDetails[0]];
  }else {
    newDetails = flyoutDetails;
  }
  _.each(newDetails, (entry) => {
    const flyoutValue = _.get(entry, flyoutEntry.field, '');
    let formattedFlyoutValue = formatFlyoutValue(flyoutValue, flyoutEntry);
    if(isChoroplethMap){
      formattedFlyoutValue = `${_.trim(formattedFlyoutValue)}`;
    }
    content += `<div>${formattedFlyoutValue} </div>`;
  });
  return content;
}

const isStackLayer = (layerId) => {
  return (
    layerId === POINT_AND_STACK_LAYERS.STACKS_CIRCLE ||
    layerId === POINT_AND_STACK_LAYERS.STACKS_LABEL
  );
}

const getReportFlyoutDetails = _.memoize( async(rowId, advanceSearchTemplateId) => {
  return await getMapFlyoutData({ row_id_field: rowId,
      currentDrilldownTemplateId: advanceSearchTemplateId,
      isCustom: true
  });
},(rowId) => rowId);

const getSubjectFlyoutDetails = _.memoize( async(rowId, advanceSearchTemplateId, subjectSearchField) => {
  return await getMapFlyoutData({
    row_id_field: rowId,
    currentDrilldownTemplateId: advanceSearchTemplateId,
    advanceSearchSubjectField: JSON.stringify(subjectSearchField),
  });
},(rowId) => rowId);

import { disableMetricTotal } from "common/config/viewConfiguration";
import { getFlyoutData, getMetricItems, getMetricValues, getRateOfChange } from "./helper";
import { getPrimaryMetricName } from "helpers/displayNameHelper";

export const lineChartFlyoutFormatter = (data, options) => {
  const { formattedData, years, showRateOfChange } = getFlyoutData(data, options);
  const primaryItems = getMetricItems(formattedData, 'primary', false);
  const secondaryItems = getMetricItems(formattedData, 'secondary', false);

  const clonedYears = _.reverse(_.cloneDeep(years));
  const tableHeaders = getFlyoutHeaders(options, 
    formattedData, primaryItems, secondaryItems, showRateOfChange, clonedYears);
  const tableData = getFlyoutBodyDataFormatter(clonedYears, data, options, formattedData, showRateOfChange);

  return {
    tableHeaders,
    tableData
  }
}

const getFlyoutHeaders = (options, formattedData, primaryItems, secondaryItems, showRateOfChange, years) => {
  const {
    viewEntry,
    secondaryMetricEntry,
    projectionEnabled,
    compareYearRanges
  } = options;

  const primaryProjectionData = projectionEnabled ? getMetricItems(formattedData, 'primary') : null;
  const secondaryProjectionData = !_.isEmpty(secondaryMetricEntry) && projectionEnabled ?
    getMetricItems(formattedData, 'secondary') : null;
  
  const primaryMetricName = getPrimaryMetricName(viewEntry);
  const secondaryMetricName = _.get(secondaryMetricEntry, 'name');

  if (_.isEmpty(secondaryItems) && _.isEmpty(secondaryProjectionData)
    && _.isEmpty(primaryItems) && _.isEmpty(primaryProjectionData)
    && _.isEmpty(formattedData)) {
    return null;
  }

  const headerOptions ={
    viewEntry, 
    secondaryMetricEntry,
    years,
    showRateOfChange,
    primaryItems, 
    secondaryItems,
    primaryProjectionData, 
    secondaryProjectionData
  }  

  if (!_.isEmpty(compareYearRanges) && _.size(compareYearRanges) === 1) {
    return getYearOnYearHeaderData(headerOptions);
  }

  let tableHeaders = [{
     name: "", 
     columnField: "dimension", 
     lineColorType: 'fillSquare', 
     lineColor: 'dimensionColor' 
    }]

  if(!_.isEmpty(primaryItems)){
    tableHeaders.push(createHeader(primaryMetricName, 'primary'))
  }
  
  if(!_.isEmpty(primaryProjectionData)){
    const projectionName = _.isEmpty(primaryItems) ? `${primaryMetricName} (Projected)` : 'Projected';
    tableHeaders.push(createProjectionHeader(projectionName,'primary'));
  }

  if (!_.isEmpty(secondaryItems)) {
    tableHeaders.push(createHeader(secondaryMetricName, 'secondary'))
  }
  
  if(!_.isEmpty(secondaryProjectionData)){
    const projectionName = _.isEmpty(secondaryItems) ? `${secondaryMetricName} (Projected)` : 'Projected';
    tableHeaders.push(createProjectionHeader(projectionName,'secondary'));
  }

  return tableHeaders;
}

const createHeader = (headerName, metricType) => {
  return {
    name: headerName, 
    columnField: metricType === 'primary' ? "dimensionValue" : "secondaryValue", 
    lineColorType: metricType === 'primary' ? 'primaryMarkerType': "secondaryMarkerType", 
    lineColor: 'color',
    metricType,
  }
}

const createProjectionHeader = (headerName, metricType) => {
  return {
    name: headerName,
    isProjection: true,
    columnField: metricType === 'primary' ? 'primaryProjectionValue' : 'secondaryProjectionValue',
    metricType,
    lineColorType: '',
    lineColor: 'color'
  }
}

const getYearOnYearHeaderData = (headerOptions) => {
  const { 
    viewEntry, 
    secondaryMetricEntry, 
    primaryItems, 
    secondaryItems, 
    years, 
    showRateOfChange, 
    primaryProjectionData, 
    secondaryProjectionData 
  } = headerOptions

  let tableHeaders = [{ 
    name: "", 
    columnField: "dimension", 
    lineColorType: 'fillSquare', 
    lineColor: 'dimensionColor' 
  }];
  const primaryMetricName = getPrimaryMetricName(viewEntry);
  const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
  const showRateOfChangeSecondary = !_.isEmpty(secondaryMetricEntry) && showRateOfChange;

  const primaryHeaderItem = createYearOnYearHeaders(years, "primary", primaryMetricName, primaryItems);
  if(!_.isEmpty(primaryHeaderItem.headers) && !_.isEmpty(primaryItems)){
    tableHeaders.push(...primaryHeaderItem.headers);
  }

  if(!_.isEmpty(primaryProjectionData)){
    const projectionName = !_.isEmpty(primaryHeaderItem.noDataYears) ? 
      `${_.last(primaryHeaderItem.noDataYears)} (Projected)` : 'Projection';
    
    tableHeaders.push(createProjectionHeader(projectionName, 'primary'));
  }

  if (showRateOfChange) {
    tableHeaders.push(createRateOfChangeHeader("primary"));
  }

  if (!_.isEmpty(secondaryItems)) {
    const secHeaderItem = createYearOnYearHeaders(years, "secondary", secondaryMetricName, secondaryItems);
    
    if(!_.isEmpty(secHeaderItem.headers) && !_.isEmpty(secondaryItems)){
      tableHeaders.push(...secHeaderItem.headers);
    }

    if(!_.isEmpty(secondaryProjectionData)){
      const projectionName = !_.isEmpty(secHeaderItem.noDataYears) ? 
      `${_.last(secHeaderItem.noDataYears)} (Projected)` : 'Projection';

      tableHeaders.push(createProjectionHeader(projectionName,'secondary'));
    }
    
    if (showRateOfChangeSecondary) {
      tableHeaders.push(createRateOfChangeHeader("secondary"));
    }
  }
  return tableHeaders;
}

const createYearOnYearHeaders = (years, metricType, metricName, dataItem) => {
  let headers = [];
  let noDataYears = [];
  _.forEach(years, (itemYear) => {
    const segmentType = _.get(itemYear, 'segmentType');
    const findData = _.find(dataItem, (datum) => _.get(datum, 'year') == itemYear.year);

    if(!_.isEmpty(findData)){
      let columnField = metricType === 'primary' ? 'dimensionValue' : 'secondaryValue';
      let lineColorType = metricType === 'primary' ? 'primaryMarkerType' : 'secondaryMarkerType';

      columnField = `${columnField}-${itemYear.year}`;
      const headerPrimary = {
        name: itemYear.year,
        columnField, 
        lineColorType,
        metricType,
        segmentType,
        lineColor: `color${itemYear.year}`,
        metricName: segmentType === 'current' ? metricName : _.isEmpty(headers) ? metricName : '',
      };

      headers.push(headerPrimary);
    } else {
      noDataYears.push(itemYear.year);  
    }

  });
  return { headers, noDataYears };
}

const getFlyoutBodyDataFormatter = (years, data, options, formattedData, showRateOfChange) => {
  const { compareYearRanges, viewEntry } = options;
  let cloneFormattedData = _.cloneDeep(formattedData);
  const isTotalAvailable = _.some(_.get(_.first(formattedData), 'metricValues'), (metricValue) => { 
    return _.get(metricValue, 'isTotal', false);
  });

  if(!isTotalAvailable && !disableMetricTotal(viewEntry) && _.size(compareYearRanges) < 2){
    cloneFormattedData = _.reverse(cloneFormattedData);
  }
  let flyoutDetails = [];
  if (!_.isEmpty(compareYearRanges) && _.size(compareYearRanges) === 1) {
    return createYearOnYearBodyData(years, data, cloneFormattedData, showRateOfChange);
  }

  _.forEach(cloneFormattedData, (datum) => {
    let dimensionColumnName = _.get(datum, 'category');
    const metricValues = _.reverse(_.get(datum, 'metricValues'));
    const metricColor = _.get(datum, 'color');

    _.forEach(metricValues, (metricValue) => {

      const rawValue = _.get(metricValue, 'rawValue');
      const formatValue = _.get(metricValue, 'metricValue');
      const segmentType = _.get(metricValue, 'segmentType');
      const metricType = _.get(metricValue, 'value');
      const traceId = _.get(metricValue, 'traceId');
      const isProjection = _.get(metricValue, 'isProjection');
      const isPrimary = metricType === 'primary';

      const findPointData = getPointData(data, metricType, dimensionColumnName, segmentType, traceId);

      const pointData = _.first(findPointData)
      const flyoutDataIndex = _.findIndex(flyoutDetails, { dimension: dimensionColumnName });
      let valueField = isPrimary ? 'dimensionValue' : 'secondaryValue';
      const colorTypeField = isPrimary ? 'primaryMarkerType' : 'secondaryMarkerType';
      const projectionValueField = isPrimary ? 'primaryProjectionValue' : 'secondaryProjectionValue';
       
      if(isProjection){
        valueField = projectionValueField;
      }

      const lineType = isPrimary ? 'solid' : _.get(pointData, 'lineType', 'dot')

      if (flyoutDataIndex > -1) {
        flyoutDetails[flyoutDataIndex][valueField] = formatValue;
        if(!isProjection){
          flyoutDetails[flyoutDataIndex][colorTypeField] = lineType;  
        }
      } else {
        const flyoutData = {
          dimension: dimensionColumnName,
          categoryMarkerType: "fillSquare",
          color: _.get(metricValue, 'color', metricColor),
          dimensionColor: _.get(metricValue, 'color', metricColor),
          rawValue: rawValue,
          [valueField]: formatValue,
          segmentType: segmentType,
          metricType: _.get(pointData, 'metricType'),
          [colorTypeField]: lineType,
        }
        flyoutDetails.push(flyoutData);
      }

    })
  });

  return flyoutDetails;
}

const createYearOnYearBodyData = (years, data, formattedData, showRateOfChange) => {
  const flyoutDetails = [];
  
  _.forEach(formattedData, (datum) => {
    let dimensionColumnName = _.get(datum, 'category');
    const metricValues = getModifyMetricValues(_.reverse(_.get(datum, 'metricValues')));
    const metricColor = _.get(datum, 'color');

    let rateOfChange = 0;
    let rateOfChangeSecondary = 0;
    if (showRateOfChange) {
      rateOfChange = getRateOfChangeValue(metricValues, "primary");
      rateOfChangeSecondary = getRateOfChangeValue(metricValues, "secondary");
    }

    _.forEach(years, (itemYear) => {
        const filterMetricValues = _.filter(metricValues, (datum) => {
          return _.get(datum, 'year') == itemYear.year;
        });

      _.forEach(filterMetricValues, (metricValue) => {
        const rawValue = _.get(metricValue, 'rawValue');
        const formatValue = _.get(metricValue, 'metricValue');
        const segmentType = _.get(metricValue, 'segmentType');
        const metricType = _.get(metricValue, 'value')
        const metricYear = _.get(metricValue, 'year', itemYear.year);
        const traceId = _.get(metricValue, 'traceId');
        const isPrimary = metricType === 'primary';
        const isProjection = _.get(metricValue, 'isProjection');

        const findPointData = getPointData(data, metricType, dimensionColumnName, segmentType, traceId);

        const pointData = _.first(findPointData)
        const flyoutDataIndex = _.findIndex(flyoutDetails, { dimension: dimensionColumnName });
        const isCurrent = _.get(pointData, 'segmentType') === 'current';
        let valueField = isPrimary ? 'dimensionValue' : 'secondaryValue';
        valueField = `${valueField}-${metricYear}`;
        const colorTypeField = isPrimary ? 'primaryMarkerType' : 'secondaryMarkerType';
        const colorLineType = isPrimary ? 'solid' : _.get(pointData, 'lineType');
        const colorDynamic = `color${metricYear}`;
        const dimensionColor = _.get(pointData, 'color', metricColor);
        const rateOfChangeFieldPrimary = `primaryChangeValue`;
        const rateOfChangeFieldSec = `secondaryChangeValue`;
        const projectionValueField = isPrimary ? 'primaryProjectionValue' : 'secondaryProjectionValue';

        if(isProjection){
          valueField = projectionValueField;
        }

        if (flyoutDataIndex > -1) {
          flyoutDetails[flyoutDataIndex][valueField] = formatValue;
          flyoutDetails[flyoutDataIndex][colorTypeField] = colorLineType;
          flyoutDetails[flyoutDataIndex][colorDynamic] = _.get(pointData, 'color', metricColor);
          flyoutDetails[flyoutDataIndex][rateOfChangeFieldPrimary] = rateOfChange;
          flyoutDetails[flyoutDataIndex][rateOfChangeFieldSec] = rateOfChangeSecondary;
          
          if (isCurrent) {
            flyoutDetails[flyoutDataIndex]['dimensionColor'] = dimensionColor;
          }
        } else {
          const flyoutData = {
            dimensionColor,
            dimension: dimensionColumnName,
            categoryMarkerType: "fillSquare",
            [colorDynamic]: _.get(pointData, 'color', metricColor),
            rawValue: rawValue,
            [valueField]: formatValue,
            segmentType: _.get(metricValue, 'segmentType'),
            metricType: _.get(pointData, 'metricType'),
            [colorTypeField]: isProjection ? 'solid' : colorLineType,
            [rateOfChangeFieldPrimary]:rateOfChange,
            [rateOfChangeFieldSec]: rateOfChangeSecondary
          }
          flyoutDetails.push(flyoutData);
        }

      })
    });
  });
  return flyoutDetails;
}

const getPointData = (data, metricType, dimensionColumnName, segmentType, traceId) => {
  const findPointData = _.compact(_.map(data, (point) => {
    const pointLine = _.get(point, 'data.line');
    const pointMeta = _.get(point, 'data.meta');
    const pointCategory = _.get(pointMeta, 'dimension');
    const pointSegmentType = _.get(pointMeta, 'segmentType');
    const pointMetricType = _.get(pointMeta, 'value') // primary / secondary
    const pointTraceId = _.get(pointMeta, 'traceId');
    if (pointMetricType === metricType 
      && pointCategory === dimensionColumnName 
      && pointSegmentType === segmentType 
      && pointTraceId === traceId) {
      return {
        color: _.get(pointLine, 'color'),
        lineType: _.get(pointLine, 'dash'),
        dimension: pointCategory,
        segmentType: pointSegmentType,
        metricType: pointMetricType,
        pointIndex: _.get(point, 'pointIndex'),
        isProjection: _.get(point, 'data.isProjection'),
        ProjectionType: _.get(point, 'data.ProjectionType'),
        isYearOnYear: _.get(point, 'data.isYearOnYear'),
        year: _.get(pointMeta, 'year')
      }
    }
  }))
  return findPointData;
}

const getRateOfChangeValue = (metricValues, metricType) => {
  const currentYear = _.map(
    getMetricValues(metricValues, metricType, 'current'), 'rawValue');
  const previousYear = _.map(
    getMetricValues(metricValues, metricType, 'comparison'), 'rawValue');

  return getRateOfChange(currentYear, previousYear)
}

const createRateOfChangeHeader = (metricType) => {
  let rateOfChangeField = metricType === 'primary' ? 'primaryChangeValue' : 'secondaryChangeValue';
  return {
    name: 'Change',
    columnField: rateOfChangeField,
    metricType,
    lineColorType: '',
    lineColor: ''
  }
}

const getModifyMetricValues = (metricValues) => {
  return _.map(metricValues, (metricValue) => {
    if(_.get(metricValue, 'isProjection') === true){
      const newYear = _.trim(_.replace(metricValue.traceId, metricValue.dimension, ""));
      metricValue.year = newYear;
    }
    return metricValue
  });
}
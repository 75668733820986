import React from 'react';
import PropTypes from 'prop-types';
import { ForgeDivider, ForgeIcon } from '@tylertech/forge-react';
import { formatValueToCurrency } from 'helpers/numberHelper';
import classNames from 'classnames';

function BarChartFlyoutContent({
  index,
  data,
  nullValueLabel,
  isCurrencyField,
  isLastIndex,
}) {
  const formatValue = formatValueToCurrency(
    data || nullValueLabel,
    isCurrencyField
  );

  const fontClass = classNames('font-weight-bold', {
    'mt-1': isLastIndex,
  });

  return (
    <div key={index} className='bar-chart-y-value text-nowrap forge-typography--subtitle1'>
      <div className="d-flex">
        {index > 0 && <ForgeIcon className="sub-tooltip-icon" name="subdirectory_arrow_right" />}
        <span className={fontClass}>{formatValue} </span>
      </div>
      {isLastIndex && <ForgeDivider className="mt-1 mb-1 pb-1" />}
    </div>
  );
}

BarChartFlyoutContent.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.number,
  nullValueLabel: PropTypes.string,
  isCurrencyField: PropTypes.bool,
  isLastIndex: PropTypes.bool,
};

export default BarChartFlyoutContent;
